import React from "react";
import { PageLayout } from "../../Layout/PageLayout";
import {
  Grid,
  Typography,
  FormControl,
  TextField,
  FormLabel,
  Box,
  Autocomplete,
  Divider,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Paper,
  Stack,
  Alert
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useLoaderData, useNavigate, useParams, useResolvedPath } from "react-router";
import { ProductPreviewCard } from "../../../components/category/productPreviewCard";
import { ValidatedButton } from "../../../components/UI/Base";
import { nullUndefinedTofalse } from "../../../utils/stringUtils";
import {
  createCategory,
  updateCategory,
} from "../../../services/CategoryService";
import { AddProductToCategoryCard } from "../../../components/category/AddProductToCategoryCard";

/**
 * Category Data
 * @typedef {{name: string ,
 *  description: string,
 * onlineOrdering: boolean
 * siteDisplay: boolean
 * visibleToCustomers: boolean
 * }} CategoryData
 *
 */

export const Category = () => {
  let data = useLoaderData();
  let navigate = useNavigate();
  /*
   * @type {CategoryData}
   */
  let categoriesInfo = {
    name: data?.name,
    description: data?.description,
    onlineOrdering: data?.onlineOrdering,
    siteDisplay: data?.siteDisplay,
    visibleToCustomers: data?.visibleToCustomers,
  };

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues: categoriesInfo });

  let URLParams = useParams();
  let idMenu = URLParams.idMenu;
  let idCategory = URLParams.idCategory;
  let idStoreLocation = URLParams.idStoreLocation;
  let isNewCategory = idCategory === 'new'
  let componentMode = URLParams?.idCategory === 'new' ? "CREATE":"UPDATE";

  let pathResolve = useResolvedPath();

  const onCategorieCreateOrUpdate = async (values) => {
    if (componentMode === "UPDATE") {
      values.onlineOrdering = nullUndefinedTofalse(values.onlineOrdering);
      values.siteDisplay = nullUndefinedTofalse(values.siteDisplay);
      values.visibleToCustomers = nullUndefinedTofalse(
        values.visibleToCustomers
      );

      console.log("Categorie updated with ", values);
      let { data: saved_category } = await updateCategory(values, idCategory);
      console.log("The saved Category ", saved_category.id_menu);
      let new_categorie_location = `${saved_category.product_category_id}`;
      //TODO : Catch error on saving
      navigate("");
    } else if (componentMode === "CREATE") {
      ///temp solution
      values.onlineOrdering = nullUndefinedTofalse(values.onlineOrdering);
      values.siteDisplay = nullUndefinedTofalse(values.siteDisplay);
      values.visibleToCustomers = nullUndefinedTofalse(
        values.visibleToCustomers
      );

      console.log("Categorie created with ", values);
      let { data: saved_category } = await createCategory(values,idStoreLocation);
      console.log("The saved Category ", saved_category.id_menu);
      let newProductCategoryId = `${saved_category.product_category_id}`;
      //TODO : Catch error on saving
      debugger
      let path = newProductCategoryId;
      navigate(`../${newProductCategoryId}`,{relative:"path"});
    }
  };

  const ControlledCheckBox = ({ control, name, label }) => {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <FormControlLabel
              control={<Checkbox />}
              label={label}
              checked={value}
              onChange={onChange}
            />
          );
        }}
      />
    );
  };

  //load data
  console.log("idMenu", idMenu);

  return (
    <PageLayout
      headerChain={["Inventory", "Menu", "Categories"]}
      pageTitle="Category"
      primaryAction={[
        {
          action: handleSubmit(onCategorieCreateOrUpdate),
          title: componentMode.toLowerCase() + " category",
        },
      ]}
      backAction={{
        url: "../../categories",
      }}
    >
      <Grid item container direction="column" gap={"2em"} width={"100%"}>
        <Paper
          sx={{
            p: 2,
          }}
        >
          <Box
            component={"form"}
            width={"100%"}
            sx={{
              "& > .MuiFormControl-root": { marginBottom: 1 },
            }}
            onSubmit={handleSubmit(onCategorieCreateOrUpdate)}
          >
            <input
              type="hidden"
              {...register("id_categorie")}
              value={data?.id_categorie || ""}
            ></input>
            <input
              type="hidden"
              {...register("id_menu")}
              value={idMenu || ""}
            ></input>
            <FormControl fullWidth>
              <FormLabel>Name</FormLabel>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                type="text"
                fullWidth
                variant="outlined"
                {...register("name", {
                  required: "You need to enter a valid name",
                  value: data?.name,
                })}
              />
            </FormControl>
            <p>
              {errors.name?.type === "required" && (
                <span>{errors.name?.message}</span>
              )}
            </p>

            <FormControl fullWidth>
              <FormLabel>Description</FormLabel>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                type="text"
                fullWidth
                variant="outlined"
                multiline
                minRows={4}
                {...register("description", {
                  value: data?.description || "",
                })}
              />
            </FormControl>

            <FormGroup>
              <FormLabel omponent="legend">Visiblity</FormLabel>
              <ControlledCheckBox
                name="visibleToCustomers"
                control={control}
                label={"show to customer"}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel omponent="legend">Available for</FormLabel>
              <ControlledCheckBox
                name="onlineOrdering"
                control={control}
                label={"Online Ordering"}
              />
              <ControlledCheckBox
                name="siteDisplay"
                control={control}
                label={"Online display"}
              />
            </FormGroup>
          </Box>
        </Paper>
        <Paper
          sx={{
            p: 2,
          }}
        >
          <Box>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  paddingBottom: "8px",
                }}
              >
                Items
              </Typography>
            { !isNewCategory && <AddProductToCategoryCard idCategory={idCategory} /> }
            { isNewCategory && <Alert variant="filled" severity="info">
              You need to save the category before being able to add items
          </Alert>}
          </Box>
        </Paper>
      </Grid>
    </PageLayout>
  );
};
