import { Paper } from "@mui/material";
import React from "react";
import {
  BarChart,
  Bar,
  Legend,
  Tooltip,
  YAxis,
  XAxis,
  CartesianGrid,
} from "recharts";

    export const ChartPrice = ({data}) => {
  

  return (
    <Paper sx={{
        p:2,
        width:"max-content"
    }}>
      <BarChart width={730} height={320} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="amount" fill="#519cf2" />
        {/* <Bar dataKey="uv" fill="#82ca9d" /> */}
      </BarChart>
    </Paper>
  );
};
