import { useState , useCallback } from "react";

/**
 * The configuration holder for the request
 * @typedef  {Object} RequestConfig
 * @property {string} url - The url use in the fetch call
 * @property {string} method - The http method
 * @property {Headers} headers - The header
 * @property {string} body
 */

/**
 * @param {RequestConfig} requestConfig
 * @param {callback} applyData
 */
const useHttp =  (requestConfig, applyData) => {
  const [error, setError] = useState(null);
  const [is_loading, setIsLoading] = useState(false);

  const requestData = async () => {
    setError(null);
    setIsLoading(true);
    try {
      const response = await fetch(requestConfig.url, {
        method: requestConfig.method ? requestConfig.method:"GET",
        headers: requestConfig.headers ? requestConfig.headers:{},
        body: requestConfig.body && JSON.stringify(requestConfig.body)
      });

      if (!response.ok || response.status !== "200") {
        throw new Error("Invalid response");
      }

      const data = await response.json();
      applyData(data);
    } catch (err) {
      setError(err.message || "Something is wrong");
    }
    setIsLoading(false)
  };

  return {
    is_loading,
    error,
    requestData
  }
};

export default useHttp;
