import React, { useEffect } from "react";
import { Outlet, redirect, useNavigation } from "react-router-dom";
import "./Layout/route.scss";
import { NavBar } from "../components/navbar/NavBar";
import { SideBarMUI } from "../components/sidebar/SideBarMUI";
import { Backdrop, Box, CircularProgress, Drawer, Grid, Toolbar } from "@mui/material";
import { Header } from "../components/header/Header";
import { RequireAuth } from "../components/authentification/RequireAuth";
import { useLoaderData, useLocation } from "react-router";
import { useAuth } from "../hooks/use-auth";
import compagnyLogo from "../static/yenda_logo_1.png";

export default function RootLayout() {
  const navigation = useNavigation();
  const location = useLocation();
  const auth = useAuth();
  const { token, duration } = useLoaderData();

  useEffect(() => {
    setTimeout(() => {
      auth.logout();
    }, duration);
  }, []);

  //When the url change reload the page
  // useEffect(()=>{
  //     return redirect("");
  // },[location])

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };


  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)
  return (
    // Here will be the side bar
    // bgcolor: "blueivy.backgroundMainColor"
    <Box sx={{ display:"flex" }}>
          <Header
            mobileMoreAnchorEl={mobileMoreAnchorEl}
            handleMobileMenuClose={handleMobileMenuClose}
            handleMobileMenuOpen={handleMobileMenuOpen}
          />
      <Grid item sx={{ marginTop: "60px" }}>
        <Grid
          item
          container
          direction={"row"}
          sx={{ width: "100%" }}
          wrap="nowrap"
        >
          {/* Navbar*/}
          <Drawer
            id="side-bar"
            variant="permanent"
            sx={{
              borderRight: "1px solid rgb(99, 107, 116, 0.2)",
              display: { xs: "none", md: "block" },
              height: "100%",
              width: "244px",
              backgroundColor: "white",
              flexShrink: 0,
              zIndex:10,
              [`& .MuiDrawer-paper`]: { width: 244, boxSizing: 'border-box' },
            }}
          >
            <Toolbar/>
            <SideBarMUI isOpen={isMobileMenuOpen} />
          </Drawer>
          <Grid
            width={"100%"}
            sx={{
              // marginLeft: { md: "244px" },
              background: "#f2f3f7",
              flexGrow:1
            }}
          >
            <Backdrop
              open={navigation.state === "loading"}
              sx={{
                backgroundColor: "#fffa",
                zIndex: 1300,
              }}
            >
              <CircularProgress />
            </Backdrop>
            {navigation.state !== "loading" && <Outlet></Outlet>}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
