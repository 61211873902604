import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  TextField,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Box,
  Typography
} from "@mui/material";
import { Form, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { createMenu, createMenuForStoreLocation, updateMenu } from "../../services/menuServices";




export const MenuEditingDialog = ({ menuItemInfo, open, onCloseHanlder, onMenuAddHandler }) => {
  // const [isEditingConfirmed, setIsEditingConfirmed] = useState(false);
  const navigate = useNavigate();
  const param = useParams()

  const idStoreLocation = param.idStoreLocation

  const onMenuAdd = async (fields)=>{

    console.log(fields);
    //call the method to create a new menu
    let response = null;
    try{

      if(fields.id_menu === "" || fields.id_menu === null){
        response = await createMenuForStoreLocation(fields,idStoreLocation);
        
      }
      else{
        response = await updateMenu(fields);
      }
      console.log(response);
      onCloseHanlder(null,"CANCEL_ACTION")
      navigate("")
    }
    catch{
      //error popup
    }
    //if no error ??
    
  }

  const onModalClose = (e) =>{
    clearErrors();
    onCloseHanlder(e,"CANCEL_ACTION");

  }
  
  
  const dialogTitle =
    menuItemInfo === null ? "Add a new menu" : "Edit the menu";
   
   //menu form
   const {
    register,
    formState: { errors },
    handleSubmit,
    clearErrors
  } = useForm(); 


  const errorState = {
    name: errors.name?.type === "required"
  }
  return (
    <>
      <Dialog
        open={open}
        fullWidth
        onClose={onCloseHanlder}
        maxWidth={"md"}
        
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <Box component={"form"} onSubmit={handleSubmit(onMenuAdd)}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <input type="hidden" {...register("id_menu")} value={menuItemInfo?.id_menu || ""} ></input>
              <input type="hidden" {...register("id_restaurant")} value={menuItemInfo?.id_restaurant || ""} ></input>
              <FormControl fullWidth>
                <FormLabel component={"div"} required>Name</FormLabel>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  type="text"
                  helperText={errors.name?.message || "Provide a name for your menu"}
                  fullWidth
                  error={errorState.name}
                  variant="outlined"
                  {...register("name", { required: "You need to enter a valid name" ,value:menuItemInfo?.name  || ""})}

                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl fullWidth>
                <FormLabel>Description</FormLabel>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  multiline
                  minRows={12}
                  {...register("description",{value:menuItemInfo?.description  || "" })}
                />
              </FormControl>
            </Grid>
          </Grid>
          </Box>
          
        </DialogContent>
        <DialogActions>
          <Button  variant="outlined" size="small" onClick={onModalClose}>Cancel</Button>
          <Button variant="contained" size="small" onClick={handleSubmit(onMenuAdd)}>Save</Button>
        </DialogActions>
      </Dialog>
   
    </>
  );
};