import React, { useEffect } from 'react'
import { useRouteError , useNavigate ,Navigate } from 'react-router'
import "./error.scss"
import  UnauthorizedRequestError  from "../../../utils/Error"

function ErrorPage() {
    /**
     * error :UnauthorizedRequestError
     */
    debugger
    const error = useRouteError();

    if (error instanceof UnauthorizedRequestError){
      return (
        <Navigate  to="/login" state={{errorMessage:"You must be login"}}/>
      )
    }
    else{
      return ( <>
        
        <div className="error">
            <h1>Oops!</h1>
            <p>Sorry, an unexpected error just happened</p>
            <p>
                <i>{error.statusText || error.message}</i>
            </p>
        </div>
      
        </>
      )
    }

  }

export default ErrorPage