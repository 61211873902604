import {LocationModal} from "../../components/Location/LocationModal";

export const Location = () =>{

    //define all the differents components

    return (
        <LocationModal/>
    )

}
