import React, { useState } from "react";
import { ResponsiveImg, RowItem } from "../UI/Base";
import defaultImg from "../../static/default.png";
import { MenuEditingDialog } from "./MenuEditingDialog";
import { Link, useNavigate } from "react-router-dom";
import { CardInventoryBase, CardInventoryContent } from "./CardInventory";

export const MenuItem = ({
  id_menu,
  name,
  marginBottom,
  categorieCount,
  presentationImage,
  description,
  productCategories,
  onClick,
  optionActionMenuHandler,
  actionMenu
}) => {
  const navigate = useNavigate();

  const menuEditHandler = (e) => {
    onClick(name);
  };

  //TODO : simplify the depth of all content

  let subContent = productCategories?.length + " categories" || ""
  
  return (
    <RowItem marginBottom={marginBottom}>
      <CardInventoryContent
        link={`/menus/${id_menu}/categories`}
        content={{ name: name, subContent: subContent }}
        onClick={menuEditHandler}
        image={defaultImg}
        optionActionMenu={actionMenu}
        optionActionMenuHandler={optionActionMenuHandler}
        option
      />
    </RowItem>
  );
};
