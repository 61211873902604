import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  Grid
} from "@mui/material";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { data_categories } from "../../../services/dashboard_services";
import { ProductInfo } from "../../../components/products/forms/ProductInfo";
import { Pricing } from "../../../components/products/forms/Pricing";
import { useLoaderData, useLocation, useNavigate, useParams } from "react-router";
import {
  createProduct,
  createProductWithImages,
} from "../../../services/productService";
import { PageLayout } from "../../Layout/PageLayout";
import { CategoryProductSelector } from "../../../components/products/forms/CategoryProductSelector";
import { PhotoGrid } from "../../../components/products/forms/PhotoGrid";
import { OptionGroupCollection } from "../../../components/products/optionGroup/OptionGroupCollection";
import { BadgeTitle } from "../../../components/UI/Base";


const ProductCreate = () => {
  //setup the form
  const payload = useLoaderData();
  const data = payload?.data;
  let { idProduct, idStoreLocation } = useParams(); 

  let { state } = useLocation();
  const navigate = useNavigate();

  const dataInfo = {
    idProduct : data.id,
    name: data.name,
    description: data.description,
    productCategoryIds: data.product_category_ids,
    basePrice: data?.product_details?.base_price,
    salePrice : data?.product_details?.sale_price,
  }

  
  const methods = useForm({ defaultValues: dataInfo });
  const [categories, setCategories] = useState([]);

  /**
   *
   * @param {Object} data
   */
  const onFormSubmit = async (data) => {
    debugger
    const result = await createProductWithImages();
    navigate("/product/" + result?.id);
  };

  console.count("render");
  return (
    <PageLayout
      pageTitle={data.name}
      subtitle="Overview of your store"
      primaryAction={[
        {
          action: methods.handleSubmit(onFormSubmit),
          title: "Save",
        },
        {
          action: () => console.log("product deleted"),
          destructive:true,
          title: "Delete product",
        },
        
      ]}
      secondaryActions={{
        title:"More actions",
        actions:[
          {
            title:"Publish",
            action:()=>{
             alert("The product has been published")
            }
          },
          {
            title:"Mark as draft",
            action:()=>{
              alert("The product has been published")
            }
          }
        ]
      }}
      backAction={{
        url: state?.backUrl,
      }}
      badgeTitle={<BadgeTitle text={"Active"} variant="active"/>}
    >
      <FormProvider {...methods}>
        <Box component={"form"} onSubmit={methods.handleSubmit(onFormSubmit)} width={"100%"}>
          <Grid container gap={2} direction={"column"}>
            <ProductInfo data={data} idStoreLocation={idStoreLocation} />
              <Grid container item>
                <PhotoGrid idProduct={idProduct} />
              </Grid>
              <Grid item md={12}>
                <OptionGroupCollection idProduct={dataInfo.idProduct} />
              </Grid>
            {/* <Grid item md={12}>
              <Button variant="contained" type="submit">
                {data ? "UPDATE" : "CREATE"}
              </Button>
            </Grid> */}
          </Grid>
        </Box>
      </FormProvider>
    </PageLayout>
  );
};
export default ProductCreate;
