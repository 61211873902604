import { CardContent, Card, Grid, Box, Typography, IconButton } from "@mui/material";
import React from "react";
import { RowItem } from "../UI/Base";
import { Link } from "react-router-dom";
import { Delete } from "@mui/icons-material";

export const ProductPreviewCard = ({id, name, description,  imageUrl , onRemoveProduct}) => {


  return (
    <RowItem key={id}>
      <Box>
        <Grid container flexWrap={"nowrap"}>
          <Grid container item flex={1} gap={1} alignItems={"center"}>
            <Grid
              item
              minWidth={"40px"}
            >
              <img
                style={{
                    borderRadius: "4px",
                    overflow: "clip",
                }}
                width={"40px"}
                src={
                  imageUrl ||
                  "https://mealboxassets.s3.us-east-2.amazonaws.com/images/poke-chips.png"
                }
                alt=""
              />
            </Grid>
            <Link to={`/store-location/1/product/${id}`} state={{ backUrl:window.location.href}} style={{
              color:"black"
            }} >
              <Typography component="h5">{name}</Typography>
            </Link>
          </Grid>
          <Grid item>
            <IconButton onClick={onRemoveProduct.bind(null,id)}>
              <Delete/>
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </RowItem>
  );
};
