import React, { useEffect, useState } from "react";
import { Typography, Divider, Grid, Stack, Paper } from "@mui/material";
import { OptionGroup } from "./OptionGroup";
import { OptionContainerDialog } from "./OptionContainerDialog";
import { AddItemButton } from "../../menuInventory/AddItemButton";
import { useForm, useFormContext } from "react-hook-form";
import { getModifierGroup } from "../../../services/productService";

/**
 * @typedef {{ optionCollections: Array<object> }} OptionCollectionProps
 * @param {OptionCollectionProps} props
 * @returns
 */

const OptionGroupItemDefault = {
  name: "",
  optionElements: [{ name: "", price: 0.0 }],
};

export const OptionGroupCollection = (props) => {
  //TODO: try to use the binding method
  const [selectedItem, setSelectedItem] = useState(null);
  const [modifierGroupItems, setOptionGroupItems] = useState(
    []
  );

  //Modifiers group
  useEffect(() => {
    const fetchModifiersGroup = async () => {
      let response = await getModifierGroup(props.idProduct);
      return response.data || [];
    };

    fetchModifiersGroup().then((data) => setOptionGroupItems(data));
  }, []);

  const selectItemHandler = (item) => {
    setSelectedItem(item);
  };

  const onCloseDialogHandler = () => {
    setSelectedItem(null);
  };

  const onAddNewOptionGroupHandler = () => {
    setSelectedItem(OptionGroupItemDefault);
  };

  const saveOptionGroupHandler = (item) => {

    //ckeck if the element already exists
    debugger
    let savedModifierItem = modifierGroupItems.filter(({idModifierGroup})=> idModifierGroup === item.idModifierGroup );
    if (savedModifierItem.length === 0) {
      //create a new item
      setOptionGroupItems((oldItem) => {
        return [item, ...oldItem];
      });
    } else {
      //modify an existing item
      let newOptionsItems = modifierGroupItems.slice();
      const indexItem = modifierGroupItems.findIndex(({ idModifierGroup }) => idModifierGroup === item.idModifierGroup);

      if (indexItem !== -1) {
        newOptionsItems[indexItem] = item;
      }
      setOptionGroupItems(newOptionsItems);
    }

    onCloseDialogHandler();
  };


  let modifierItemCount = modifierGroupItems.length;

  return (
    <Paper
      sx={{
        p: 2,
      }}
    >
      <Stack direction={"column"} gap={1}>
        <Typography variant="h6" color="text.secondary" component={"span"}>
          Modifiers
        </Typography>
        <Stack direction={"column"} gap={2}>
          {modifierGroupItems.map((option, index) => {
            return (
              <Grid item>
                <OptionGroup
                  index={option?.name}
                  name={option?.name}
                  modifierItems={option?.modifierItems}
                  onClickHandler={selectItemHandler.bind(null, option)}
                />

                {index !== modifierGroupItems.length - 1 && <Divider />}
              </Grid>
            );
          })}
        </Stack>
        <AddItemButton
          value="Add modifier"
          onButtonClickHandler={onAddNewOptionGroupHandler}
        />
      </Stack>

      {selectedItem && (
        <OptionContainerDialog
          option={selectedItem}
          saveOptionGroupHandler={saveOptionGroupHandler}
          onCloseHandler={onCloseDialogHandler}
          newPositonOnCreation={modifierItemCount + 1}
        />
      )}
    </Paper>
  );
};
