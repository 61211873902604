import { Container, Grid } from "@mui/material";
import React, { useState } from "react";
import { ProductGrid } from "../../../components/products/listing/ProductGrid";
import { ProductFilter } from "../../../components/products/listing/ProductFilter";
import { useLoaderData } from "react-router";
import { PageLayout } from "../../Layout/PageLayout";


export const ProductList = () => {
  const { data } = useLoaderData();

  const [products, setProducts] = useState(data);

  const filterProducts = (value) => {
    if (value === 3) {
      //All
      setProducts(data);
    } else {
      setProducts(data.filter((prod) => prod.inventory_state === value));
    }
  };

  return (
    <PageLayout
      pageTitle="Products"
      subtitle="The list of all the products in  your store"
    >
      <Grid container gap={2} direction={"column"}>
        <ProductFilter filterValuesHandler={filterProducts} />
        <ProductGrid products={products} />
      </Grid>
    </PageLayout>
  );
};
