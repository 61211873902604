import React from "react";
import { MetricInfo } from "../../components/metrics/MetricInfo";
import { data_metrics , chartDataPrice } from "../../services/dashboard_services.js";
import "./home.scss";
import { Button, TextField, Box, Typography, Stack , Grid } from "@mui/material";
import { CancelButton, ValidatedButton } from "../../components/UI/Base";
import { PageLayout } from "../Layout/PageLayout.jsx";
import { ChartPrice } from "../../components/charts/ChartPrice.jsx";

function Home() {
  return (
    <PageLayout pageTitle={"Dashboard"} subtitle={"Overview of your store"}>
      <Grid container gap={2} direction={"column"}>
        <Stack direction={"row"} gap={2}>
          {data_metrics.map((metrics) => (
            <MetricInfo
              metric_title={metrics.title}
              metric_value={metrics.value}
              percent_change={metrics.percent_change}
              key={metrics.title}
            />
          ))}
        </Stack>
        <Grid item>
          <Typography variant="body1">
          General insights
          <br />
          Revenue are up 24 % as compared to last month
          </Typography>
        </Grid>
        <Grid>
        <Typography variant="h3" gutterBottom>
            Comparison price evolution
          </Typography>
          <ChartPrice data={chartDataPrice}/>
        </Grid>
      </Grid>
    </PageLayout>
  );
}

export default Home;
