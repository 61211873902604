import React, { useState } from "react";
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    DialogTitle,
    TextField,
    Button,
    FormControl,
    FormLabel,
    Grid,
    Box, Stack, Typography
} from "@mui/material";
import { Form, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";


/**
 *
 * @param onCloseHanlder: CallableFunction
 *
 * @returns {JSX.Element}
 * @constructor
 */

export const LocationModal = ({onCloseHanlder}) =>{


    const {register,
        handleSubmit,
    formState:{errors}} = useForm(
        {
            defaultValues:{
                "name":"",
                "address1":"",
                "address2":"",
                "city":"",
                "zipCode":""
            }
        }
    );

    const saveNewLocation = (data) =>{

        try {
            //send the data to the server
            console.log(data);
        }
        catch (err){
            console.error("The error are ",err)
        }
    }
    if(errors){
        console.log(errors);
    }

    return (
        <Dialog
            open={true}
            onClose={onCloseHanlder}
            sx={{
                "& .MuiPaper-root": {
                    // width: "70%",
                },
            }}
        >
            <DialogContent>
                <Box  sx={{
                            md: {
                                width:"70%"
                            },
                            sm: {
                                width:"100%"
                            }
                        }}>
                    <DialogTitle>Add a new location for your restaurant</DialogTitle>
                    <Box component={"form"} onSubmit={handleSubmit(saveNewLocation)}>
                        <Stack direction={"column"} gap={2} justifyContent={"center"} >
                            <FormControl>
                                <FormLabel>Store name</FormLabel>
                                <TextField
                                    error={errors.name?true:false}
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    type="text"
                                    {...register("name", { required: "You need to enter a valid name" })}

                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Address line 1</FormLabel>
                                <TextField
                                    error={errors.address1?true:false}
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    type=""
                                    fullWidth
                                    variant="outlined"
                                    {...register("address1", { required: "You need to enter a valid name" })}

                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Address line 2</FormLabel>
                                <TextField
                                    error={errors.address2?true:false}
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    {...register("address2", { required: "You need to enter a valid name" })}

                                />

                                    {errors.address2?.type === "required" && (
                                        <Box >
                                            <Typography variant="alert">
                                                The entered adress is not valid
                                            </Typography>
                                        </Box>
                                    )}

                            </FormControl>
                            <FormControl>
                                <FormLabel>City</FormLabel>
                                <TextField
                                    error={errors.city?true:false}
                                    autoFocus
                                    margin="dense"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    {...register("city", { required: "You need to enter a valid name" })}

                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Zip code</FormLabel>
                                <TextField
                                    error={errors.zipCode?true:false}
                                    autoFocus
                                    margin="dense"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    {...register("zipCode", { required: "You need to enter a valid zip code" ,
                                        pattern:/\b[ABCEGHJKLMNPRSTVXY]\d[A-Z] \d[A-Z]\d\b/i
                                    })}

                                />
                            </FormControl>

                        </Stack>

                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleSubmit(saveNewLocation)} type="submit">Save</Button>
            </DialogActions>
        </Dialog>

    );
}