import React, { useState } from 'react'
import { Dialog,
DialogActions,
DialogContent,
DialogTitle,
Button,
Box,
FormControl,
FormLabel,
TextField,
Grid, 
InputAdornment,
Divider} from '@mui/material';
import { useNavigate } from 'react-router';
import { createMenu , updateMenu} from '../../../services/menuServices';
import { FormProvider, useForm } from 'react-hook-form';
import { createProduct } from '../../../services/productService';
import { CategoryProductSelector } from '../forms/CategoryProductSelector';

export const ProductCreateDialog = ({ open, onCloseHanlder, onMenuAddHandler }) => {
    // const [isEditingConfirmed, setIsEditingConfirmed] = useState(false);
    const navigate = useNavigate();
    const onProductCreate = async (fields)=>{
  
      console.log(fields);
 
      //call the method to create a new menu
      let response = null;
      try{
  
        if(fields !== null){
          response = await createProduct(fields);
          
        }
       
        console.log(response);
        debugger
        onCloseHanlder(null,"CANCEL_ACTION")
        navigate(`/product/${response.data.id}`)
      }
      catch{
        //error popup
      }
      //if no error ??
      
    }
  
    
    
    const dialogTitle = "Add a new product";
     //TODO: add error handling for the form
     //menu form
     const {
      register,
      formState,
      handleSubmit,
    } = useForm(); 
    return (
      <><FormProvider register={register} formState={formState}  handleSubmit={handleSubmit}>
        <Dialog

          open={open}
          onClose={onCloseHanlder}
          sx={{
            "& .MuiPaper-root": {
              width: "70%",
            },
          }}
        >
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
            <Box component={"form"} onSubmit={handleSubmit(onProductCreate)}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                {/* <input type="hidden" {...register("id_restaurant")}></input> */}
                <FormControl fullWidth>
                  <FormLabel>Name</FormLabel>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    {...register("name", { required: "You need to enter a valid name"})}
                   
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl fullWidth>
                  <FormLabel>Description</FormLabel>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    multiline
                    minRows={4}
                    {...register("description",{maxLength:300})}
                  />
                </FormControl>
              </Grid>
              {/* <Grid item>
                <FormControl fullWidth>
                  <FormLabel>Sales price</FormLabel>
                  <TextField
                    id="standard-basic"
                    size="small"
                    type="number"
                    {...register("product_details.base_price")}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">CAD $</InputAdornment>
                      ),
                      inputProps: { min: "0", max: "1000", step: "0.1" },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl fullWidth>
                  <FormLabel>Base price</FormLabel>
                  <TextField
                    id="standard-basic"
                    size="small"
                    type="number"
                    {...register("product_details.sale_price")}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">CAD $</InputAdornment>
                      ),
                      inputProps: { min: "0", max: "1000", step: "0.1" },
                    }}
                  />
                </FormControl>
              </Grid> */}
              <Grid item>
                <CategoryProductSelector/>
              </Grid>
            </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={(e)=>onCloseHanlder(e,"CANCEL_ACTION")}>Cancel</Button>
            <Button onClick={handleSubmit(onProductCreate)}>Save</Button>
          </DialogActions>
        </Dialog>
     </FormProvider>
      </>
    );
  };
