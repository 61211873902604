export default class UnauthorizedRequestError extends Error{

    constructor(message,reason){
        super(message);

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, UnauthorizedRequestError);
          }

          this.name="UnauthorizedRequestError";
          this.reason = reason
    }
}