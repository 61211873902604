import React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Collapse, Drawer, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import { menuItems } from "../../services/menus";
import { Link, NavLink } from "react-router-dom";
import { BorderClear } from "@mui/icons-material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const MenuItemLink = ({ route_path, name, title, Icon, children }) => {
  return (
    <NavLink
      to={route_path}
      style={{
        all: "unset",
      }}
    >
      {({ isActive, isPending }) => (
        <ListItemButton
          sx={{
            "&.MuiButtonBase-root": {
              width: "100%",
              padding: "4px 8px",
              color: "primary",
            },
            "&.Mui-selected": {
              backgroundColor: "#F0F7FF", //color item bar selected
              color: "blueivy.primary",
              "&:hover": {
                // backgroundColor: "#3B82F6",
              },
              ".MuiListItemIcon-root": {
                color: "blueivy.primary",
              },
            },
          }}
          disableTouchRipple="true"
          selected={isActive && route_path}
          key={name}
          // onClick={(event) => handleListItemClick(event, name)}
        >
          <ListItemIcon
            color="seoondary"
            sx={{
              minWidth: "36px",
            }}
          >
            {Icon && <Icon />}
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="menu">{title}</Typography>}
          />
        </ListItemButton>
      )}
    </NavLink>
  );
};

export const SideBarMUI = ({ isOpen }) => {
  const [selectedIndex, setSelectedIndex] = React.useState(null);

  const handleListItemClick = (event, index) => {
    setSelectedIndex((old_index) => {
      return old_index !== index ? index : null;
    });
  };

  const MenuDropdownItemList = ({
    route_path,
    name,
    title,
    Icon,
    children,
  }) => {
    return (
      <ListItemButton
        color="primary"
        sx={{
          borderRadius: "4px",
          "&.MuiButtonBase-root": {
            width: "100%",
            padding: "4px 8px",
          },
          "&.Mui-selected": {
            backgroundColor: "#3B82F6",
            color: "white",
            "&:hover": {
              backgroundColor: "#3B82F6", //hover background color
            },
            ".MuiListItemIcon-root": {
              color: "white",
            },
          },
        }}
        disableTouchRipple="true"
        key={name}
        onClick={(event) => handleListItemClick(event, name)}
      >
        <ListItemIcon
          sx={{
            minWidth: "36px",
            "& > svg": {
              width: "24px",
            },
          }}
        >
          {Icon && <Icon />}
        </ListItemIcon>
        <ListItemText
          primary={<Typography variant="menu">{title}</Typography>}
          sx={{}}
        />
        {children && (
          <ChevronRightIcon
            sx={{
              transform: selectedIndex === name ? "rotate(90deg)" : "",
            }}
          />
        )}
      </ListItemButton>
    );
  };

  const MenuItemList = menuItems.map(
    ({ key, title, icon: Icon, route_path, type, children }) => {
      if (type === "menu") {
        return (
          <>
            {route_path ? (
              <MenuItemLink
                key={key}
                route_path={route_path}
                name={key}
                title={title}
                Icon={Icon}
                children={children}
              />
            ) : (
              <MenuDropdownItemList
                key={key}
                name={key}
                title={title}
                Icon={Icon}
                children={children}
              />
            )}
            {children && (
              <Collapse
                backgroundColor={"#3B82C4"}
                in={selectedIndex === key}
                timeout="auto"
              >
                <List component="div" disablePadding>
                  {children.map(({ title, route_path }) => {
                    return (
                      <MenuItemLink title={title} route_path={route_path} />
                    );
                  })}
                </List>
              </Collapse>
            )}
          </>
        );
      } else {
        return <Divider />;
      }
    }
  );

  return (
    <Box sx={{
      overflow: "auto",
    }} >
        <List
          component="nav"
          aria-label="main mailbox folders"
          sx={{
            "& .MuiButtonBase-root": {
              marginBottom: "8px",
            },
          }}
        >
          {MenuItemList}
        </List>
    </Box>
  );
};
