import React, { useState , useMemo } from "react";
import {
  AppBar,
  Box,
  Grid,
  IconButton,
  Stack,
  Toolbar,
  Menu,
  MenuItem,
} from "@mui/material";
import { StoreSelector } from "./StoreSelector";
import compagnyLogo from "../../static/yenda_logo_white.png";
import { NotificationHeader } from "./NotificationHeader";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MoreVertIcon from '@mui/icons-material/MoreVert';


export const Header = ({mobileMoreAnchorEl,handleMobileMenuClose,handleMobileMenuOpen}) => {
  
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const renderMobileMenu =  useMemo(()=>(
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <p>Store</p>
      </MenuItem>
      <MenuItem>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem></MenuItem>
    </Menu>
  ),[isMobileMenuOpen, mobileMoreAnchorEl]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        position: "fixed",
        width: "100%",
        zIndex: "1",
        height: "64px",
      }}
    >
      <AppBar position="static" sx={{
        background:"white",
        borderBottom:"1px solid gray",
        boxShadow:"none",
        position:"fixed",
        zIndex: (theme) => theme.zIndex.drawer + 99
      }}>
        <Toolbar>
          <Box p={1} sx={{ flexGrow: 1 }}>
            <img src={compagnyLogo} alt="compagny logo" height="32px" />
          </Box>
          <IconButton
            sx={{
              color: "white",
            }}
            onClick={() => {}}
          >
            <AccountCircleIcon fontSize="large" />
          </IconButton>
          <StoreSelector  xs={{display:{xs: 'none',md: 'block'}}}/>
          <NotificationHeader  xs={{display:{xs: 'none',md: 'block'}}}/>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </Box>
  );
};
