import React, { useEffect } from "react";
import { useContext, useState } from "react";
import authContext from "./AuthContext";
import { getRestaurantInfos } from "../../services/RestaurantStoreService";
import { redirect } from "react-router";


const contextInit = () =>{

  //load the user and the token from memory if one

  const token = localStorage.getItem("_TOKEN");
  const store_location = document.cookie;

  //or we can decode the token
  return {
    userInfo:{
      first_name:null,
      last_name:null,
      name:null,
      email:null // load this from the user provider decoded
    },
    json_token:token,
    logout:()=>{
      throw redirect("/logout")
    },
    store_location:1 // load this from the cookie storage
  }

}
export const AuthProvider = ({ children }) => {


  let [userContextInfo, setUserContextInfo] = useState(()=>contextInit());

  let value = {userContextInfo, setUserContextInfo}

  //TODO: Store the current store location into the context
  //load it from the context
  
  return <authContext.Provider value={value}>{children}</authContext.Provider>
};
