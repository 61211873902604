import UnauthorizedRequestError from "../utils/Error";

//base path to interrogate the server
const BASEPATH = "/api/v1/";

/**
 * 
 * @param {String} url 
 * @param {String} method 
 * @param {Object} payload 
 * @param {string} [contentType] 
 * @returns 
 */

export let fetching = async (url, method, payload, contentType) => {
  const JSON_TOKEN = localStorage.getItem("_TOKEN");
  const headers = JSON_TOKEN && {
    Authorization: "Bearer " + JSON_TOKEN,
    "Content-Type": contentType || "application/json"
  };
  const fetchUrl = BASEPATH + url;
  // add possibility to get the JSON_TOKEN of the currently login user
  let response = null;

    response = await fetch(fetchUrl, {
      method: method,
      headers: headers || undefined,
      body: payload,
    });
 
  if (!response.ok) {
    switch (response.status) {

      case 401:
        console.log("Request unauthorized");
        throw new UnauthorizedRequestError("Your credentials are not valid","UNAUTHORIZED_REQUEST");
        break;
        
      case 404:

        break;

      case 403:

        break;

      case 500:
        throw new UnauthorizedRequestError("A server error happened");
        break;

      default:
        break;
      } 

  } 
  else {
    const jsonResponse = await response.json();
    return jsonResponse;
  }
};
