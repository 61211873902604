import {
  TextField,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  Grid,
  Checkbox,
  FormControl,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import { debounce } from "@mui/material/utils";
import { searchProducts } from "../../services/CategoryService";
import { useParams } from "react-router";
import { RowItem } from "../UI/Base";
import { CloseOutlined } from "@mui/icons-material";

export const AddProductToCategoryModal = ({
  idCategory,
  open,
  productIds,
  onClose,
  category,
  onSaveProduct,
  onCloseHandler,
}) => {
  const [products, setProducts] = useState([]);
  const [productName, setProductName] = useState("");
  const [selectedProductIds, setSelectedProductIds] = useState(productIds || []);

  const param = useParams();
  const idStoreLocation = param.idStoreLocation;

  const searchProductByName = useMemo(
    () =>
      debounce((request, callback) => {
        // autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  /**
   * @param idProducts: Array
   */
  const addProductsToCategory = (idProducts) => {
    if (selectedProductIds.includes(idProducts)) {
      setSelectedProductIds((oldProducts) =>
        oldProducts.filter((item) => item !== idProducts)
      );
    } else {
      setSelectedProductIds((oldProducts) => [idProducts, ...oldProducts]);
    }
    console.log("product with ids" + selectedProductIds);
  };

  const saveProducts = ()=>{
    onSaveProduct(selectedProductIds);
    onCloseHandler();
    
  }

  useEffect(() => {
    // const fetch
    searchProducts(null, productName, idStoreLocation).then((result) =>
      setProducts(result.data || [])
    );
  }, [idStoreLocation, productName]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={onCloseHandler}
      maxWidth={"md"}
      sx={{
        minHeight: "400px",
      }}
    >
      <DialogTitle>Add products</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCloseHandler}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[400],
        }}
      >
        <CloseOutlined />
      </IconButton>
      <DialogContent>
        <Box>
          <FormControl fullWidth>
            <TextField
              autoFocus
              id="search_name"
              type="text"
              fullWidth
              value={productName}
              onChange={(event) => setProductName(event.target.value)}
              variant="outlined"
            />
          </FormControl>
        </Box>
        <Box>
          {products &&
            products.map((p) => {
              return (
                <RowItem
                  key={p.id}
                  onClick={addProductsToCategory.bind(null, p.id)}
                >
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <Grid container item spacing={2} alignItems={"center"}>
                      <Grid item>
                        <img
                          style={{
                            borderRadius: "4px",
                            overflow: "clip",
                          }}
                          width={"40px"}
                          src={
                            p.imageUrl ||
                            "https://mealboxassets.s3.us-east-2.amazonaws.com/images/poke-chips.png"
                          }
                          alt=""
                        />
                      </Grid>
                      <Grid item>{p.name + " " + p.id}</Grid>
                    </Grid>
                    <Grid item>
                      {<Checkbox checked={selectedProductIds.includes(p.id)} />}
                    </Grid>
                  </Stack>
                </RowItem>
              );
            })}
          {products.length === 0 && (
            <Typography> No product available</Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" size="small" onClick={onCloseHandler}>
          Cancel
        </Button>
        <Button variant="contained" size="small" onClick={saveProducts}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
