export const data_metrics = [
    {
        "title":"Revenues",
        "value":"$100",
        "percent_change":{
            "increase":false,
            "value":7.5
        }
    },
    {
        "title":"Order",
        "value":"250",
        "percent_change":{
            "increase":true,
            "value":40
        }
    },
    {
        "title":"Pages view",
        "value":"1400",
        "percent_change":{
            "increase":true,
            "value":17
        }
    },
    
]

export const data_categories =[
    "Boissons",
    "Amuse gueule",
    "Croquette",
    "Poulets"
]

export  const chartDataPrice = [
    {month:"Jan",amount: 200},
    {month:"Feb",amount: 250},
    {month:"Mar",amount: 290},
    {month:"Avr",amount: 180},
    {month:"may",amount: 340},
    {month:"Jun",amount: 250},
    {month:"Jul",amount: 286},
    {month:"Aug",amount: 310},
    {month:"Sep",amount: 304},
    {month:"Oct",amount: 338},
    {month:"Nov",amount: null },
    {month:"Dec",amount: null},
   
]

export const storeLocation = [
    "Yuzu Capital",
    "Yuzu Ste Foy",
    "Yuzu Montreal Nord"
]

