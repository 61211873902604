import React from "react";
import {
  Card,
  CardHeader,
  Typography,
  FormGroup,
  Box,
  FormControl,
  FormLabel,
  TextField,
  Stack,
  InputAdornment,
  Grid,
  Paper,
} from "@mui/material";
import { useFormContext } from "react-hook-form";
import { CategoryProductSelector } from "./CategoryProductSelector";
import { ControlledCheckBox } from "../../UI/Base";
import { useAuth } from "../../../hooks/use-auth";

export const ProductInfo = ({data,idStoreLocation}) => {
  const {
    register,
    control,
    formState: { isDirty, errors },
  } = useFormContext();


  return (
    <Paper
      elevation={1}
      sx={{
        p: 2,
      }}
    >
      <Box
        sx={{
          "& > .MuiFormControl-root:first-child": { marginTop: "0px" },
        }}
      >
        <FormControl fullWidth margin="normal">
          <FormLabel>Nom</FormLabel>
          <TextField
            id="standard-basic"
            name="name"
            size="small"
            {...register("name")}
            color="primary"
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <FormLabel>Description</FormLabel>
          <TextField
            id="standard-basic"
            name="description"
            size="small"
            multiline
            rows={4}
            {...register("description")}
          />
        </FormControl>
        <Grid item md={6} sm={12}>
          <CategoryProductSelector
            data_categories={data?.product_category_ids}
            location={idStoreLocation}
          />
        </Grid>

        <Stack direction={"row"} gap={2}>
          <FormControl margin="normal">
            <FormLabel>Base price</FormLabel>
            <TextField
              id="standard-basic"
              min="0.0"
              max="10000.0"
              step="0.1"
              name="regular_price"
              size="small"
              type="number"
              {...register("basePrice")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">CAD $</InputAdornment>
                ),
              }}
            />
          </FormControl>
          <FormControl margin="normal">
            <FormLabel>Sales price</FormLabel>
            <TextField
              id="standard-basic"
              name="promo_price"
              size="small"
              type="number"
              {...register("salePrice")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">CAD $</InputAdornment>
                ),
                inputProps: { min: "0", max: "1000", step: "0.1" },
              }}
            />
          </FormControl>
        </Stack>
        <Stack>
          {/* TODO : add the real value name */}
          <FormGroup component="legend" >
            <FormLabel>Available for</FormLabel>
            <ControlledCheckBox
              name="onlineOrdering"
              control={control}
              label={"Online Ordering"}
            />
            <ControlledCheckBox
              name="onlineOrdering"
              control={control}
              label={"Delivery"}
            />
          </FormGroup>
          <FormGroup component="legend">
            <FormLabel>Visibility</FormLabel>
            <ControlledCheckBox
              name="onlineOrdering"
              control={control}
              label={"available to customer"}
            />
          </FormGroup>
        </Stack>
      </Box>
    </Paper>
  );
};
