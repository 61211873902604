import ViewKanbanOutlinedIcon from "@mui/icons-material/ViewKanbanOutlined";
import InboxOutlinedIcon from "@mui/icons-material/InboxOutlined";
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';

export  const menuItems = [
   
    {
      key: "dashboard",
      title: "Dashboard",
      icon: ViewKanbanOutlinedIcon,
      route_path: `dashboard`,
      type: "menu"
    },
    {
      key: "test",
      title: "Dashboard",
      icon: ShoppingBasketOutlinedIcon,
      route_path: null,
      type: "menu"
    },
    {
      key: "orders",
      title: "Orders",
      icon: InboxOutlinedIcon,
      route_path: `products`,
      type: "menu",
    },
    {
      key: "inventory",
      title: "Inventory",
      icon: InboxOutlinedIcon,
      route_path: null,//product
      type: "menu",
      children:[
        {
            title:"Categories",
            route_path:'categories'
        },
        {
            title:"Products",
            route_path:'products'
        },
        {
          title:"Menus",
          route_path:'menus'
        }
      ]
    },
    {
    
      type: "divider",
    },
    {
        key: "contenu",
        title: "Contenu",
        icon: LayersOutlinedIcon,
        route_path: null,
        type: "menu",
    },
    {
        key: "notfications",
        title: "Notifcations",
        icon: Inventory2OutlinedIcon,
        route_path: null,
        type: "menu",
    },
    {
        type: "divider",
      },
    {
        key: "restaurant",
        title: "Restaurant",
        icon: StorefrontOutlinedIcon,
        route_path: `restaurant`,
        type: "menu",
    },
    {
        key: "users",
        title: "users",
        icon: PersonOutlineOutlinedIcon,
        route_path: `users`,
        type: "menu",
    },
    {
        
        key: "account",
        title: "Account",
        icon: SettingsOutlinedIcon,
        route_path: `account`,
        type: "menu",
    }

  ];