import { IconButton, Badge } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
export const NotificationHeader = ({ onNotificationClickHandler, xs }) => {
  const notificationCount = 3;

  return (
    <IconButton onClick={onNotificationClickHandler}
        sx={{
            color:"white",
            ...xs
        }}>
      <Badge badgeContent={notificationCount} invisible={notificationCount === 0} color="success">
        <NotificationsIcon fontSize="medium"  />
      </Badge>
    </IconButton>
  );
};
