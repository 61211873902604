import {
  AddOutlined,
  BorderLeftOutlined,
  DeleteOutline,
} from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Input,
  Typography,
  FormLabel,
  TextField,
  FormControl,
  Grid,
  Icon,
  Button,
  CardActions,
  MenuItem,
  Select,
} from "@mui/material";

import { red } from "@mui/material/colors";

import React, { useReducer, useState } from "react";
import { OptionElement } from "./OptionElement";

function optionTypeReducer(state, action) {
  switch (action.value) {
    case 0:
      return {
        option: action.value,
        isChecked: false,
      };
    case 1:
      return {
        option: action.value,
        isChecked: true,
      };
  }
}

export const OptionGroup = ({
  name,
  onNameChange,
  onDeleteSelf,
  id,
  elements

}) => {
  const [stateChoice, dispatchChoiceChange] = useReducer(optionTypeReducer, {
    option: "Multiple choices",
    isChecked: false,
  });


  const init_elements = elements.map((ele)=> {return {name:ele,disabled:true}});
  //define the option elements
  //for testing
  //TODO : Reafactor it into its own component
  const [optionElement, setOptionElement] = useState(init_elements);

  const addOptionEventHandler = () => {
    const new_id = optionElement.length;
    let newOption = {name:`new option ${new_id + 1}`,disabled:false}
    setOptionElement((oldOptions) => [...oldOptions, newOption]);
  };

  const onDeleteOptionEventHandler = (option) => {
    setOptionElement((old_options) =>
      old_options.filter((o) => o.name != option)
    );
  };

  const onOptionTypeChange = (e) => {};

  const OptionType = () => {
    let optionsType = ["Multiple choices", "Single choice"];
    return (
      <FormControl fullWidth size="small">
        <FormLabel>Select the categories for the product</FormLabel>
        <Select
          onChange={(e) => dispatchChoiceChange({ value: e.target.value })}
          value={stateChoice.option}
        >
          {optionsType.map((choice, index) => (
            <MenuItem value={index} key={index}>
              {choice}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  return (
    <Card
      style={{
        borderLeft: "8px solid blue",
      }}
    >
      <CardHeader
        title={
          <Typography variant="h6" color="text.secondary" component={"span"}>
            Product options
          </Typography>
        }
      />
      <Divider />
      <CardContent>
        <Grid container direction={"column"} gap={2}>
          <Grid item container justifyContent={"space-between"}>
            <Grid item>
              <FormControl>
                <FormLabel>Option name</FormLabel>
                <input type="hidden" name="options" value={"ok"}/>
                <TextField
                  id="standard-basic"
                  name="promo_price"
                  size="small"
                  type="text"
                  defaultValue={name}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl>
                <OptionType />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item>
            {optionElement.length > 0 &&
              optionElement.map((option) => (
                <OptionElement
                  key={option}
                  isChecked={stateChoice.isChecked}
                  disabled={option.disabled}
                  name={option.name}
                  onDeleteSelf={onDeleteOptionEventHandler}
                />
              ))}
            <Box marginTop={"10px"}>
              <OptionElement
                disabled={false}
                key={"new"}
                isChecked={stateChoice.isChecked}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={() => onDeleteSelf(id)}>
          Delete
          <DeleteOutline />
        </Button>
        <Button size="small" onClick={addOptionEventHandler}>
          Add
          <AddOutlined />
        </Button>
      </CardActions>
    </Card>
  );
};
