import React, { useEffect, useRef, useState } from "react";
import StorefrontIcon from "@mui/icons-material/Storefront";

// import { storeLocation } from "../../services/dashboard_services";
import {
  Button,
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  MenuList,
} from "@mui/material";

import { Check, ChevronRight } from "@mui/icons-material";
import { listLocationForStore } from "../../services/authService";
import { useAuth } from "../../hooks/use-auth";
import { redirect, useNavigate, useParams } from "react-router";

export const StoreSelector = (props) => {
  const [selectedStore, setSelectedStored] = useState({
    idLocation: 3,
    name: "Garoux Ste foy",
    currency: "CAD",
    slug: null,
    country: null,
    addressLine1: null,
    addressLine2: null,
    countryCode: null,
    city: "Quebec",
    phoneNumber: null,
    taxIncluded: null,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const { userContextInfo, setUserContextInfo } = useAuth();

  const { idStoreLocation } = useParams();
  const navigate = useNavigate();

  const onStoreMenuClick = (event) => {
    //make the popup apear
    setAnchorEl(event.currentTarget);
  };
  
  const onMenuItemClick = (location) => {
    setAnchorEl(null);
    setSelectedStored(location);
    setUserContextInfo((oldContext) => {
      return {
        ...oldContext,
        store_location: {
          idLocation: location.idLocation,
          name: location.name,
        },
      };
    });

    return navigate(`/store-location/${location.idLocation}`)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let storeLocation = useRef([]);

  useEffect(() => {

    //TODO: load the data from the context
    const getStoreLocations = async () => {
      let result = await listLocationForStore(idStoreLocation);
      storeLocation.current = result?.data || [];
    };
    getStoreLocations();
  }, []);
  return (
    <Box  sx={{
     ...props.xs
    }}>
      <ListItemButton
        onClick={onStoreMenuClick}
        sx={{
          "&.MuiListItemButton-root": {
            border: "1px solid gray",
            padding: "4px 8px",
            minWidth: "200px",
            backgroundColor:"white",
            ".MuiListItemIcon-root": {
              minWidth: "36px",
              color: "blueivy.primary",
            },
          },
        }}
      >
        <ListItemIcon>
          <StorefrontIcon />
        </ListItemIcon>
        <ListItemText primary={<Typography>{selectedStore.name}</Typography>} />
        <ListItemIcon
          sx={{
            transform: isMenuOpen ? "rotate(90deg)" : "",
            justifyContent:"center"
          }}
        >
          <ChevronRight />
        </ListItemIcon>
      </ListItemButton>
      <Menu
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={2}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: 1,
            marginTop: 1,
            minWidth: 220}
        }}
      >
        {storeLocation.current.map((location, index) => (
          <MenuItem onClick={onMenuItemClick.bind(null, location)} key={index}>
            <ListItemIcon>
              {location.idLocation === selectedStore.idLocation && <Check />}
            </ListItemIcon>
            {location.name}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
