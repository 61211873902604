import React, { useEffect, useCallback, useMemo, useState } from "react";
import {
  Box,
  Button,
  debounce,
  Stack,
  Grid,
  CircularProgress,
} from "@mui/material";
import {
  addProductsToCatgerory,
  deleteProductFromCategory,
  searchProducts,
} from "../../services/CategoryService";
import { ProductPreviewCard } from "./productPreviewCard";
import { AddProductToCategoryModal } from "./AddProductToCategoryModal";

export const AddProductToCategoryCard = ({ idCategory }) => {
  const [products, setProducts] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const openAddProductModal = useCallback(() => setIsDialogOpen(true), []);
  const closeProductModal = useCallback(() => setIsDialogOpen(false), []);

  const onRemoveProductFromCategory = useCallback(
    (idProduct) => {
      console.log("Deleted ", idProduct);

      deleteProductFromCategory(idProduct, idCategory).then(
        setProducts((oldCategorised) => {
          return oldCategorised.filter((p) => p.id !== idProduct);
        })
      );
    },

    []
  );

  const saveAddProductToCategory = (ids_product) => {
    console.log("Products with ids " + ids_product + " added");
    debugger;
    addProductsToCatgerory(idCategory, ids_product).then(
      loadProducts(idCategory)
    );
  };

  let loadProducts =  useCallback((idCategory) => {
    setIsLoading(true);
    searchProducts(idCategory).then((result) => {
      setProducts(result.data);
      setIsLoading(false);
    });
  },[]);

  useEffect(() => {
    // const fetch
    loadProducts(idCategory);
    //.then((data)=>console.log(data));
  }, [idCategory]);

  const productIds = products.map((p) => p.id);

  return (
    <>
      {!isLoading && (
        <Box>
          <Stack justifyContent={"end"} width={"100%"} direction={"row"} mb={1}>
            <Grid item>
              <Button onClick={openAddProductModal}>Add product</Button>
            </Grid>
          </Stack>
          {products &&
            products.map((product) => (
              <Box flexBasis={"300px"}>
                <ProductPreviewCard
                  {...product}
                  onRemoveProduct={onRemoveProductFromCategory}
                ></ProductPreviewCard>
              </Box>
            ))}
        </Box>
      )}
      {isLoading && <CircularProgress />}
      {isDialogOpen && (
        <AddProductToCategoryModal
          open={isDialogOpen}
          productIds={productIds}
          onCloseHandler={closeProductModal}
          onSaveProduct={saveAddProductToCategory}
        />
      )}
    </>
  );
};
