import UnauthorizedRequestError from "../utils/Error";
import { fetching } from "../utils/request";

export const getAllMenus = async () =>{
    //fetch all the products
    let result = null


    result = await fetching("menus");

    if(result === null){
        throw new UnauthorizedRequestError();
    }
    
    return result
}

//TODO: to move to category service
export const getMenuCategories = async ({params , request}) =>{
    //fetch all the categories of a menu
    let result = null
    const url = new URL(request.url);
    let idMenu = url.searchParams.get("menu");
    let idStoreLocation = params.idStoreLocation
    

    if(idMenu === null || idMenu === undefined){
      //categories only for a menu
      result = await fetching(`store-locations/${idStoreLocation}/categories`);
    }
    else{
        //all categories for the store location
        result = await fetching(`menus/${idMenu}/category`);
    }


    if(result === null){
        throw new UnauthorizedRequestError();
    }
    
    return result?.data
}

export const createMenuForStoreLocation =  async (menuData,idStoreLocation) =>{

    const jsonData = { 
        name:menuData.name,
        description:menuData.description
    }
    const POST_url = `store-locations/${idStoreLocation}/menus`;
    try{
        const jsonPayload = JSON.stringify(jsonData);
        const result = await fetching(POST_url,"POST",jsonPayload);
        return result;
    }

    catch (err){

    }
    return null;
}

export const updateMenu =  async (menuData) =>{

    const jsonData = { 
        id_menu:menuData.id_menu,
        name:menuData.name,
        description:menuData.description
    }
    const PUT_url = `menus/${menuData.id_menu}`;
    try{
        const jsonPayload = JSON.stringify(jsonData);
        const result = await fetching(PUT_url,"PUT",jsonPayload);
        return result;
    }

    catch (err){

    }
    return null;
}

export const deleteMenu = async (idMenu) =>{

    const DELETE_URL = `menus/${idMenu}/delete`;

    try{
        const result = await fetching(DELETE_URL,"POST",null);
        return result;
    }
    catch (err){
    }

}