import { fetching } from "../utils/request";
import {redirect} from "react-router-dom";
import { jwtDecode } from "jwt-decode";

//temporary service
export let signin =  async ({email,password})=>{
    //get the user

    const base64password_encoded = btoa(email + ":" + password);
    const url = "api/v1/users/login"

    const response = await fetch(url, {
      method: "POST",
      headers: { Authorization: "Basic " + base64password_encoded },
    });

    if (!response.ok || response.status !== 200) {
      console.log("Request failed");
      throw new Error("Invalid url");
    } else {
      return await response.json();
    }

}

export const listLocationForStore =  async (idStore) =>{

    
  const GET_url = `store/${idStore}/locations`;
  let storeLocation = null;
  try{
    storeLocation = await fetching(GET_url,"GET");
  }
  catch (err){
    //Throw a massive error 
      console.log("Missing locations")
  }
  return storeLocation;
}

export function getTokenDuration() {
  const SECONDS_TO_MILLISECONDS = 1000;
  const token = localStorage.getItem('_TOKEN');
  let duration = null;
  if (token){
    const decodedToken = jwtDecode(token);
    const {  exp: storedExprirationDate } = decodedToken;
    const expirationDate = new Date(storedExprirationDate * SECONDS_TO_MILLISECONDS);
    const now = new Date();
    duration = expirationDate.getTime() - now.getTime();
  }
  return duration;
}

export const tokenLoader = ()=>{
    //get the token from the local storage
    const duration = getTokenDuration()
    const token = localStorage.getItem("_TOKEN");
    
    if(!token || duration < 0){
      throw  redirect("/login")
    }
    return {
      token:token,
      duration:duration
    };
}

