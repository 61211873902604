import React from 'react'
import './metric_info.scss'
// import { Card } from '../UI/Card'
import KeyboardControlKeyOutlinedIcon from '@mui/icons-material/KeyboardControlKeyOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { Card,CardContent,CardHeader } from '@mui/material';


const ValueChangeWidget = ({value,increase: isUp}) => {

  return (
    <div className={`metric_value_change ${isUp? "up":"down"}`}>
        <span className="value_percent">{value}</span>
        <div className="icon">
        { isUp ? <KeyboardControlKeyOutlinedIcon/>:<KeyboardArrowDownOutlinedIcon/>}
        </div>   
    </div>
  )

}


export const MetricInfo = ({metric_title , metric_value , MetricLogo,percent_change}) => {
  return (
    <Card>
      <CardContent>
      <div className="metric_info">
          <div className="info_col">
              <div className="title">
                {metric_title}
              </div>
              <div className='value'>
                {metric_value}
              </div>
          </div>
          <div className="info_col">
            <div className='logo'>
              { MetricLogo && <MetricLogo/>} 
            </div>
            {percent_change && <ValueChangeWidget {...percent_change}/>}
          </div>
       </div>
      </CardContent>
     
    </Card>

    
  )
}
