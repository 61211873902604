import { createTheme } from "@mui/material";

/*

  - padding:8px (standard)

*/

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#3062D4",
      black:"#000000"
      //the remaining are calculating automatically
      //By MUI
      //use .contrastedThreshold to for text color on tricky component
    },
    text: {
      primary: "#4D4D4D",
    },
    //to be adjusted 
    blueivy: {
      main: "#0c0c0c",
      primary:"#3062D4",
      secondary: "#FBFCFE",
      error:"#FF0000",
      inputColor:"898989",
      backgroundColor:"#fcfcfc",//for the field 
      backgroundMainColor:"#f9fafb" // for the main container

    },
  },

  
  //button text typography (text size)
  //keep the size as it is
  //we may add the hardc coded size later
});

//we may add the responsiveSize function to automatically a responsive size
export const theme = createTheme(defaultTheme, {


  //split typography from the default theme
  typography: {
    fontFamily: ["Inter"].join(","),
    h1: {
      fontSize: 32,
      fontWeight: "bold",
    },
    h2: {
      fontSize: 24,
      fontWeight: "bold",
    },
    h3: {
      fontSize: 16,
      fontWeight: "bold",
    },
    lead:{
      fontSize: 16,
      fontWeight: "bold",
    },
    body1:{
      fontSize: 16,
        //may add another color
    },

    secondary:{
      fontSize: 14,
      fontWeight:100,
     
        //may add another color
    },
    subtitle2:{
      fontSize: 14,
      fontWeight:100
        //may add another color
    },
    alert:{
      fontSize: 16,
      fontWeight: "bold",
      color:"red"
    },
    error:{
      fontSize: 16,
      fontWeight: "regular",
      color:"red"
    },
    menu:{
      fontWeight:"500" //medium
    }
    ,
    fontSize: 16,
    color:"red"
  },

  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: "true",
        disableTouchRipple: "true",
       
      },
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: "1rem",
        },
      },
      variants: [
        {
          props: { variant: "dashed" },
          style: {
            textTransform: "none",
            border: `2px dashed ${defaultTheme.palette.primary.main}`,
            color: defaultTheme.palette.primary.main,
          },
        },
      ],
    },

    MuiButtonBase: {
      defaultProps: {
        disableElevation: "true",
        disableRipple: "true",
      },

      styleOverrides: {
        root: {
          borderRadius: "4px",
          "&.MuiButtonBase-root":{
            textTransform:"none"
          }
        },
      },
    },


    //override Formlabel
    MuiFormLabel:{
      styleOverrides: {
        // Name of the slot
        root: {
         "&.MuiFormLabel-root":{
          marginBottom:"4px",
          fontSize: 16,
          fontWeight: "600",
          color:defaultTheme.palette.text.primary,
          "&.Mui-error":{
            color:"#d32f2f"
          }
         }
        },
      },
    },

    //TODO: customize the background of a menu

    MuiTextField: {
      defaultProps:{
        size:"small"
      },
      styleOverrides: {
        // Name of the slot
        root: {
          //underline
          "& .MuiFormHelperText-root":{
            marginLeft:"0px",
            fontSize:"0.85rem"
          },

          // Some CSS
          "& label.Mui-focused": {
            color: defaultTheme.palette.blueivy.inputColor,
          },
          "&.MuiFormControl-root":{
            marginTop:"0px"
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "#A5C4FC",
          },
          "& .MuiOutlinedInput-root": {
            backgroundColor: defaultTheme.palette.blueivy.backgroundColor,
            "& fieldset": {
              borderColor: "#aab8c5", //color outline
              borderRadius: "4px",
            },
            "&:hover fieldset": {
              borderColor: defaultTheme.palette.blueivy.main,
            },
            "&.Mui-focused fieldset": {
              borderColor: defaultTheme.palette.blueivy.primary,
              boxShadow: "0px 0px 0px 4px rgba(25, 148, 238, 0.15)"
            },
            "&.Mui-focused.Mui-error fieldset": {
              borderColor: defaultTheme.palette.blueivy.error,
              boxShadow: "0px 0px 0px 4px rgba(250, 0, 0, 0.15)"
            }
          },
        },
      },
    },
    MuiAutocomplete:{
      styleOverrides: {
        // Name of the slot
        root: {
         "&.MuiAutocomplete-root":{
        
         }
        },
      },
    }
  },
});
