import {
  Button,
  Box,
  Container,
  FormControlLabel,
  Checkbox,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  alpha,
  styled,
} from "@mui/material";
import { Controller } from "react-hook-form";

export const SimpleContainer = (props) => {
  if (props.clean) {
    return <Box>{props.children}</Box>;
  } else {
    return <Container fixed>{props.children}</Container>;
  }
};

export const ValidatedButton = (props) => {
  return (
    <Button variant="contained" color="primary" type={props?.type}>
      {props.children}
    </Button>
  );
};

export const CancelButton = (props) => {
  return (
    <Button variant="outlined" color="primary">
      {props.children}
    </Button>
  );
};

export const ResponsiveImg = ({ src, alt, width }) => {
  return (
    <img
      src={src}
      alt={alt}
      style={{
        height: "auto",
        width: width,
        backgroundSize: "cover",
      }}
    />
  );
};
export const AddItemButton = (props) => {
  return (
    <Button variant="" color="primary" onClick={props.onClick}>
      {props.children}
    </Button>
  );
};

export const ControlledCheckBox = ({ _control, name, label }) => {
  return (
    <Controller
      name={name}
      control={_control}
      render={({ field: { onChange, value } }) => {
        //TODO: add it to the theme
        return (
          <FormControlLabel
            sx={{
              "& >.MuiButtonBase-root": {
                padding: "4px 9px ",
              },
            }}
            control={<Checkbox />}
            label={label}
            checked={value}
            onChange={onChange}
          />
        );
      }}
    />
  );
};

export const BadgeTitle = ({ variant, text }) => {
  const colorVariants = {
    active: "#67e0b0",
    disabled: "gray",
    error: "red",
  };

  return (
    <Box
      sx={{
        borderRadius: "4px",
        p: "4px",
        backgroundColor: colorVariants[variant] || colorVariants.disabled,
      }}
    >
      <Typography color="text.primary" variant="h3">
        {text}
      </Typography>
    </Box>
  );
};

export const PopupMenu = () => {
  return <></>;
};

export const DialogConfirmation = ({
  message,
  open,
  onActionHandler,
  onCloseHanlder,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onCloseHanlder}
      sx={{
        "& .MuiPaper-root": {
          width: "70%",
        },
      }}
    >
      <DialogContent>
        <DialogContentText>
        {message}
        </DialogContentText>
        <DialogActions>
          <Button onClick={onActionHandler}>Yes</Button>
          <Button onClick={onCloseHanlder}>Cancel</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};


/**
 * 
 * @param {React.PropsWithChildren} props 
 */
export const RowItem = (props)=>{

  return (
    <Box  {...props} sx={{
      "&.MuiBox-root":{ 
        borderBottom: "1px solid #ccc",
        padding:1,
        marginBottom:`${props.marginBottom}`,
        "&:hover":{
          backgroundColor:"#f7f7f7"
        }
      }, 
      
    }}>
      {props.children}
    </Box>
  )
  
}

const BadgeSucess = styled(Box)(({ theme }) => ({
  width: 300,
  color: theme.palette.success.main,
  "&.MuiBox-root":{
    backgroundColor:theme.palette.success.main
  }
}));

