import React, { useEffect, useState, useCallback } from "react";
import { Form, useForm, useFormContext } from "react-hook-form";
import {
  Box,
  Typography,
  Divider,
  FormControl,
  Select,
  FormLabel,
  Button,
  MenuItem,
  ListItemText,
  Checkbox,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import { listCategoriesForLocation } from "../../../services/CategoryService";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const CategoryProductSelector = ({ data_categories, location  }) => {
  //TODO : set error context on fail

  const {
    register,
    formState: { isDirty, errors },
    setValue,
  } = useFormContext();

  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(
    data_categories || []
  );
  //load the differents categories

  useEffect(() => {
    const fetchCategories = async () => {
      let result = await listCategoriesForLocation(location);
      setCategories(result.data);
      console.log(result);
    };
    fetchCategories();
  }, []);

  const onCategoryChange = (event) => {
    const value = event.target.value;
    //filter the value to remove any undefined value

    let valueSelectForm = [];
    console.log(value)
    
    let filteredValue = value.filter((v) => v !== undefined);
    setSelectedCategories(filteredValue);
  };

  return (
    <>
      <Box
        sx={{
          "& .MuiButtonBase-root": { margin: "8px 0" },
        }}
      >
        <input hidden></input>
        <FormControl fullWidth size="small">
          <FormLabel>Categories</FormLabel>
          <Select
            {...register("product_category_ids")}
            multiple
            value={selectedCategories}
            onChange={onCategoryChange}
            MenuProps={MenuProps}
            renderValue={(selected) => {
              let names = selected.map((value) => {
                let selectedName = "";
                for (const { name, product_category_id } of categories) {
                  if (product_category_id === value) {
                    selectedName = name;
                  }
                }
                return selectedName;
              });
              return names.length > 0 ? names.join(", ") : "";
            }}
          >
            {categories.map((category, i) => (
              <MenuItem
                value={category.product_category_id}
                key={category.product_category_id}
              >
                <Checkbox
                  checked={
                    selectedCategories.indexOf(category.product_category_id) >
                    -1
                  }
                />
                <ListItemText>{category.name}</ListItemText>
              </MenuItem>
            ))}
            <Divider />
            <MenuItem>
              <ListItemIcon>
                <AddCircleOutlineIcon />
              </ListItemIcon>
              <ListItemText>Create a new catgeory</ListItemText>
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    </>
  );
};
