import React from "react";
import { Box, Typography } from "@mui/material";

/**
 * @typedef {{name:string ,onClickHandler:Function }} OptionGroupProps
 * @param {OptionGroupProps} props
 * @returns
 */
export const OptionGroup = (props) => {

  const optionCount = props.modifierItems?.length || 0
  const optionItemNames = props.modifierItems?.reduce((acc,item)=>{
    return acc + item.name + ", ";
    }, '').slice(0, -", ".length);
 
  return (
    <Box onClick={props.onClickHandler}
      padding={"10px"}
      sx={{
     
      }}
      key={props.index}
    >
      {props.name}
      <Typography variant="body1"> {optionCount} option{optionCount > 0?"s":""}</Typography>
      <Typography variant="body2"> {optionItemNames} </Typography>
    </Box>
  );
};
