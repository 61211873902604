import React from 'react'
import { CardInventoryContent } from "./CardInventory";
import { useNavigate } from 'react-router';
import { RowItem } from '../UI/Base';

export const MenuCategoryItem = ({
    idCategory,
    name,
    description,
    marginBottom
}) => {

  const navigate = useNavigate();

  return (
    <RowItem marginBottom={marginBottom}>
      <CardInventoryContent 
        onClick={() => navigate(`${idCategory}`)}
        content={{ name: name, subContent: description }}
      />
    </RowItem>
  );

}
