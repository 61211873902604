import { fetching } from "../utils/request";

// TODO: to be replace with query parameter
export const getCategories = async ({params}) =>{
    //fetch the categories 
    let id_category = params?.idCategory;

    try{
        // TODO: just for test purpose , to be removed

        let url;
        if(id_category)
        {
            //Do something
            url = `category/${id_category}`;
            const result = await fetching(url);
            return result?.data;
        }
        else{
            //fetch all catgeories
            return null;
        }
       
    }
    catch (err){
       
    }
    return null;
}


export const searchProducts =async(idCategory, productName,idStoreLocation)=>{
    
    let baseURL = "products/search?";
    let urlSearchParam = new URLSearchParams();
    if(idCategory){
        urlSearchParam.set('id_product_category', idCategory);
    }
    
    
    if(productName){
        urlSearchParam.set('product_name', productName);
    }

    if(idStoreLocation){
        urlSearchParam.set("id_store_location",idStoreLocation);
    }
    
    try{
        const result = await fetching(baseURL.toString() + urlSearchParam.toString());
        return result;
    }
    catch(err){

    }
    
    return null

}

export const createCategory =  async (categorieData,idStoreLocation) =>{

    const POST_url = `store-locations/${idStoreLocation}/categories`;
    debugger
    try{
        const jsonPayload = JSON.stringify(categorieData);
        const result = await fetching(POST_url,"POST",jsonPayload);
        return result;
    }

    catch (err){

    }
    return null;
}

export const updateCategory =  async (categorieData,idCategory) =>{

    
    const POST_url = `category/${idCategory}`;
    try{
        const jsonPayload = JSON.stringify(categorieData);
        const result = await fetching(POST_url,"PUT",jsonPayload);
        return result;
    }

    catch (err){

    }
    return null;
}

export const deleteProductFromCategory = async (idCategory, idProduct) =>{

    let url = null;

    try{
        if(idCategory !== null || idProduct !== null){
            url= `category/${idCategory}/products/${idProduct}/delete`;

            const result = fetching(url,"POST");
            return result;
        }
        else{
            throw new Error("The id of the product category is missing")
        }
    }
    catch(err){

    }
    return null
}

export const listCategoriesForLocation =  async (idStoreLocation) =>{

    const GET_url = `store-locations/${idStoreLocation}/categories`;
    let categories = null;
    try{
        categories = await fetching(GET_url,"GET");
    }
    catch (err ){
        console.log("Missing categories for location")
    }
    return categories;
}


export const addProductsToCatgerory = async (idCategory,idsProduct) =>{
    if(idsProduct){
        const POST_url = `category/${idCategory}/products?ids_product=${idsProduct}`
        await fetching(POST_url,"POST")
    }
    else
        throw Error("A bad parameter")
    

}




