import {
  Grid,
  Typography,
  Container,
  Button,
  Stack,
  Box,
  Menu,
  MenuItem,
  ListItemButton,
} from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import { ArrowBack, DeleteForeverOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";

// const buildHeader = (headerChain, separator) => {
//   let newChain = [];
//   for (let i = 0; i < headerChain.length; i++) {
//     newChain.push(<span key={i}>{headerChain[i]}</span>);
//     if (i !== headerChain.length - 1) {
//       newChain.push(separator);
//     }
//   }

//   return newChain;
// };

// const header = buildHeader("", <ChevronRightIcon />);

/**
 * @typedef {{title:String,action:CallableFunction}} PageLayoutAction
 * @typedef {{pageTitle:String ,subtitle:String,
 * primaryAction:Array<PageLayoutAction>,
 * secondaryAction:Array<PageLayoutAction>}} PageLayoutProps
 * @param {PageLayoutProps}
 * @returns
 */
export const PageLayout = ({
  pageTitle,
  subtitle,
  badgeTitle,
  primaryAction,
  secondaryActions,
  backAction,
  children,
}) => {
  const primaryActionButtons = primaryAction?.map((a) => {
    return (
      <Button variant="contained" size="small" onClick={a.action}>
        {a.title}
      </Button>
    );
  });

  return (
    <>
      <Container
        sx={{
          margin: "24px auto",
          paddingBottom: "24px",
        }}
        maxWidth="lg"
      >
        <Grid container>
          <Grid
            item
            container
            className="headingTitle"
            alignItems={"center"}
            sx={{
              mt: 2,
              paddingBottom: 2,
            }}
          >
            {backAction && (
              <Grid
                item
                className="page-back"
                sx={{
                  "& a": {
                    all: "unset",
                    cursor: "pointer",
                  },
                  marginRight: 1,
                }}
              >
                <Link to={backAction.url} relative={"path"}>
                  <ArrowBack fontSize="medium" />
                </Link>
              </Grid>
            )}
            <Grid item>
              <Stack direction={"row"} >
                <Typography key={"pageTitle"} variant="h2" color="text.primary">
                  {pageTitle}
                </Typography>
                <Box pl={1}>{badgeTitle}</Box>
              </Stack>
              {subtitle && (
                <Typography
                  key={"subtitle"}
                  variant="subtitle1"
                  color="text.primary"
                >
                  {subtitle}
                </Typography>
              )}
            </Grid>
            <Grid item alignContent={"end"} marginLeft={"auto"}>
              <Stack direction={"row"} gap={1}>
                {primaryAction && primaryActionButtons}
                {secondaryActions && (
                  <SecondaryActionMenuButton
                    title={secondaryActions.title}
                    items={secondaryActions.actions}
                  />
                )}
              </Stack>
            </Grid>
          </Grid>
          <Grid item className="page-content" container lg={12}>
            {children}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

const SecondaryActionMenuButton = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleClose = useCallback(()=>{
    setAnchorEl(null);
  },[])
  return (

    
    <>
      <Button  ref={anchorEl} variant="outlined" size="small" onClick={(e)=>setAnchorEl(e.currentTarget)}>
        {props.title}
      </Button>
      <Menu
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={2}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {props.items &&
          props.items.map((item) => (
            <ListItemButton onClick={item.action}>
              {item.title}
              {item.destructive && <DeleteForeverOutlined />}
            </ListItemButton>
          ))}
      </Menu>
    </>
  );
};
