import React, { useState } from "react";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  FormControl,
  FormLabel,
  TextField,
  Box,
  Button,
  Stack,
  Typography,
  Divider,
  DialogActions,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import { OptionGroup } from "./OptionGroup_toremove";
import { OptionGroupAction } from "./OptionGroupAction";
import { useForm, useFieldArray } from "react-hook-form";
import { DeleteForever } from "@mui/icons-material";
import { saveOrUpdateModifiergroup } from "../../../services/productService";
import { ControlledCheckBox } from "../../UI/Base";

//TODO : rename it to optionCollections or optionGroupDialog ?
export const OptionContainerDialog = ({
  option,
  onCloseHandler,
  saveOptionGroupHandler,
  newPositonOnCreation
}) => {
  //OptionGroup management

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      idModifierGroup: option.idModifierGroup || null,
      name: option.name,
      isRequired:option.isRequired,
      description: option.description,
      modifierType: option.modifierType,
      modifierItems: option.modifierItems,
      position:option.position || newPositonOnCreation
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "modifierItems",
    rules: {
      required: "Add at least ine item",
    }, 
  });

  //TODO: read id from url or from context
  const onFormSubmitHandler = async (data) => {
    debugger
    console.log("Submitted data =>", data);

    //update the positon if the item is new
    if(data.idModifierGroup){
    
    }
    //send the data to the server if sucessfull add it to the other group

    let result = await saveOrUpdateModifiergroup(1, data);
    saveOptionGroupHandler(result.data);
  };

  console.log(errors);
  return (
    <Dialog open={true}>
      <DialogTitle>Modifier</DialogTitle>
      <DialogContent>
        <Box component={"form"} onSubmit={handleSubmit(onFormSubmitHandler)}>
          <Grid
            container
            direction="column"
            spacing={2}
            sx={{
              minWidth: "500px",
            }}
          >
            <Grid item>
              <FormControl fullWidth>
                <FormLabel>Name</FormLabel>
                <input type="hidden" {...register("idModifierGroup")} />
                <input type="hidden" {...register("position")} />
                <TextField
                  autoFocus
                  margin="dense"
                  type="text"
                  fullWidth
                  variant="outlined"
                  {...register(`name`, {
                    required: "You need to enter a valid name",
                  })}
                />
              </FormControl>
              {errors.name?.type === "required" && (
                <span>{errors.name?.message}</span>
              )}
            </Grid>
            <Grid item>
              <FormControl fullWidth>
                <FormLabel>Choices</FormLabel>
                <Select name="modifierType" value={option.modifierType} {...register("modifierType")} >
                  <MenuItem key={1} value="SINGLE_VALUE">Single out of many</MenuItem>
                  <MenuItem  key={2} value="MULTIPLE_VALUES">Multiple</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl fullWidth>
                <FormLabel>Description</FormLabel>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  multiline
                  minRows={4}
                  {...register("description", { maxLength: 300 })}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl>
                <FormLabel>
                  Requiered
                </FormLabel>
                <ControlledCheckBox  name="isRequired"
                    control={control}
                    label={"is the modifier required"} />
              </FormControl>
             
            </Grid>
            <Grid item>
              <Typography>Items</Typography>
              <Divider />
            </Grid>
            <Grid item container gap={2}>
              {fields.map((field, index) => {
                return (
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    spacing={1}
                    key={field.index}
                  >
                    <Grid item>
                      <FormControl fullWidth>
                        <FormLabel>Name</FormLabel>
                        <input
                          type="hidden"
                          {...register(`modifierItems.${index}.modifierItemId`)}
                        />
                        <input
                          type="hidden"
                          {...register(`modifierItems.${index}.position`, {
                            value: index + 1,
                          })}
                        />
                        <TextField
                          autoFocus
                          margin="dense"
                          type="text"
                          fullWidth
                          variant="outlined"
                          {...register(`modifierItems.${index}.name`, {
                            required: "You need to enter a valid name",
                          })}
                        />
                      </FormControl>
                    </Grid>

                    <FormControl>
                      <FormLabel>price</FormLabel>
                      <TextField
                        autoFocus
                        margin="dense"
                        type="number"
                        fullWidth
                        variant="outlined"
                        {...register(`modifierItems.${index}.price`, {
                          required: "You need to enter a valid name",
                        })}
                      />
                    </FormControl>
                    <Grid item>
                      <IconButton
                        type="button"
                        size="large"
                        onClick={() => remove(index)}
                      >
                        <DeleteForever />
                      </IconButton>
                    </Grid>
                  </Stack>
                );
              })}
            </Grid>

            {/* { errors && <Stack>
              {errors.optionElements.map((e)=><p>{e.message}</p>)}
              </Stack>} */}
          </Grid>
          <Grid item>
            <Button
              type="button"
              onClick={() =>
                append({ modifierItemId: null, name: "", price: 0.0 })
              }
            >
              Add a new item
            </Button>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            onCloseHandler();
          }}
        >
          Cancel
        </Button>
        <Button onClick={handleSubmit(onFormSubmitHandler)}>SAVE</Button>
      </DialogActions>
    </Dialog>
  );
};
