import {
    CardHeader,
    Container,
    Grid,
    Typography,
    Card,
    CardContent,
    FormControl,
    FormLabel,
    TextField,
    Box,
    Divider,
  } from "@mui/material";
  export const LoginInfo = ({data}) => {
    return (
      <Card>
        <CardHeader
          title={
            <Typography variant="h6" color="text.secondary" component={"span"}>
              Login info
            </Typography>
          }
        ></CardHeader>
        <Divider />
  
        <CardContent>
          <Box component={"form"}>
            <Grid container direction={"column"} gap={2}>
                <Grid item >
                  <FormControl fullWidth>
                    <FormLabel htmlFor="email">Account email</FormLabel>
                    <TextField id="standard-basic" name="name" size="small" />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth htmlFor="password">
                    <FormLabel>Last name</FormLabel>
                    <TextField id="standard-basic" name="name" size="small" />
                  </FormControl>
                </Grid>
             
              <Grid item container gap={2}>
                <Grid item>
                  <FormControl>
                    <FormLabel>Phone number</FormLabel>
                    <TextField id="standard-basic" name="name" size="small" />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl>
                    <FormLabel>Last name</FormLabel>
                    <TextField id="standard-basic" name="name" size="small" />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            
          </Box>
        </CardContent>
      </Card>
    );
  };