import { json } from "react-router";
import { fetching } from "../utils/request"
import UnauthorizedRequestError from "../utils/Error";
import {redirect} from "react-router-dom";


export const getProduct = async ({params})=>{
    //fetch the product bases on its id
    //TODO :to be changed to a faster version
    let result = null

    if (Object.keys(params).length === 0){
        return null;
    }

    //TODO: if params is not define return none;
  
    result = await fetching("products/"+params.idProduct);
       
   
    return result;
}

export const getAllProduct = async ({params}) =>{
    //fecth all the products
    let result = null;
    
    let idStoreLocation = params.idStoreLocation;
    if(idStoreLocation){
        result = await fetching(`store-locations/${idStoreLocation}/products`);
    }

    if(result === null){
        throw new UnauthorizedRequestError();
    }

    return result;
}

export const createProduct = async (productData) =>{
    try{
        const JSON_payload = JSON.stringify(productData);
        const result = await fetching("products","POST",JSON_payload);
        return result;
    }
    catch (err){

    }
}

export const UpdateProduct = (productData) =>{
    
}

export const createProductWithImages = async (productFormData) =>{
    try {

        const result = await fetching("products/1/productimage","POST",productFormData,'multipart/form-data');
        return result;
        
    } catch (error) {
        
    }
}

export const uploadProductImages = async (form,idProduct)=>{
    
    const url = `upload/admin/products/${idProduct}/images`
    let formData = new FormData(form)
    const result = await fetching(url,"POST",formData," ")
    return result;

}

export const getProductImages = async (idProduct)=>{
    
    const url = `products/${idProduct}/images`
    const result = await fetching(url)
    return result;

}

export const deleteProductImages = async (idProduct, idImage)=>{
    
    const url = `products/${idProduct}/images/delete/${idImage}`
    const result = await fetching(url,"POST")
    return result;

}

export const getModifierGroup = async(idProduct)=>{

    const url = `products/${idProduct}/modifiers`;
    const result = await fetching(url);

    return result;
}

export const saveOrUpdateModifiergroup = async (idProduct,data) =>{
    
    let url = null;
    let idModifiergroup = data.idModifierGroup;
    if(idModifiergroup){
        //saved mode 
        url = `products/-/modifiers/${idModifiergroup}`
    }
    else{
        //update mode 
        debugger
        url = `products/${idProduct}/modifiers`;
    }

    const jsonPayload = JSON.stringify(data);
    const result = await fetching(url,"POST",jsonPayload);

    return result;
}


export const searchProduct = async (name,idStoreLocation)=>{
    let url = null

    try{
        if(name && idStoreLocation){
            url = `products/search?id_store_location=${idStoreLocation}&product_name=${name}`;
            const result = await fetching(url);
            return result;
        }
        else{
            throw new Error(`the arguments are not valid`);
        }

    }catch(err){

    }
    return null
}


