import React from "react";
import { PageLayout } from "../../Layout/PageLayout";
import { useLoaderData, useNavigate } from "react-router";
import { Grid, Paper } from "@mui/material";
import { MenuCategoryItem } from "../../../components/menuInventory/MenuCategoryItem";
import { AddItemButton } from "../../../components/menuInventory/AddItemButton";
/**
 * A category
 *@typedef {{name: string , products: string }}  Categorie
 */

export const MenuCategories = () => {
  /**
   * @typedef {{ menuId:Number, name:string, description: string, products: Array<Product> }}  Category
   * @type {Array<Category>} categories
   */
  const categories = useLoaderData();
  const navigate = useNavigate();

  return (
    <PageLayout
      pageTitle="Categories"
      primaryAction={[
        {
          action: () => navigate("new", { relative: false }),
          title: "Create category",
        },
      ]}
    >
      <Paper
        sx={{
          p: 2,
          width: "100%",
        }}
      >
        <Grid item container direction={"column"} gap={"2em"} width={"100%"}>
          {categories &&
            categories.map((category) => {
              return (
                <MenuCategoryItem
                  name={category.name}
                  idCategory={category.product_category_id}
                  description={category.description}
                />
              );
            })}
        </Grid>
      </Paper>
    </PageLayout>
  );
};
