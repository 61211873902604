import React from "react";
import { Button } from "@mui/material";
/**
 * @typedef {{value: string, onButtonClickHandler: CallableFunction}} AddItemButtonProps
 * @param {AddItemButtonProps} props
 * @returns
 */
export const AddItemButton = (props) => {

  let {onButtonClickHandler,value , ...restProps} = props
  return (
    <Button
      onClick={onButtonClickHandler}
      variant="dashed"
      fullWidth
      sx={{
        padding: "10px",
      }}
      {...restProps}
    >
     {value}
    </Button>
  );
};
