import React from 'react'
import { Dialog,DialogContent,DialogContentText,DialogActions,Button } from '@mui/material'

export const MenuConfirmation = ({open,onDialogActionConfirmed,onCloseHanlder}) => {
  return (
    <Dialog
          open={open}
          onClose={onCloseHanlder}
          sx={{
            "& .MuiPaper-root": {
              width: "70%",
            },
          }}
        >
          <DialogContent>
            <DialogContentText>
              Are you sured you want to confirm the operation
            </DialogContentText>
            <DialogActions>
              <Button onClick={onDialogActionConfirmed}>Yes</Button>
              <Button onClick={onCloseHanlder}>No</Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
  )
}
