import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Button,
  Input,
  Box,
  IconButton,
  styled,
  Grid,
  Paper,
  Stack,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Clear, DeleteForeverRounded } from "@mui/icons-material";
import defaultImg from "../../../static/default.png";
import { DialogConfirmation, ResponsiveImg } from "../../UI/Base";
import { useFormContext } from "react-hook-form";
import {
  uploadProductImages,
  deleteProductImages,
} from "../../../services/productService";
import useHttp from "../../../hooks/use-http";
import LoadingCircular from "../../UI/Indicator";

const ImagePreview = ({ children, onSelected, selected, onDeleteHandler }) => {
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  return (
    <Box
      on555
      sx={{
        // ":hover": {
        //   border: "2px  solid blue",
        // },
        borderRadius: "8px",
        overflow: "clip",
        border: `2px solid ${selected ? "red" : "transparent"}`,
        position: "relative",
      }}
      onHover={onSelected}
    >
      <IconButton
        onClick={onDeleteHandler}
        sx={{
          position: "absolute",
          right: "10px",
          top: "8px",
        }}
      >
        <Clear />
      </IconButton>
      {children}
    </Box>
  );
};

export const PhotoGrid = ({idProduct}) => {
  const [fileImages, setFilesImages] = useState([]);
  const [isUploaded, setIsUploaded] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState(null);
  

  let { error, is_loading, requestData } = useHttp(
    {
      url: `/api/v1/products/${idProduct}/images`,
    },
    (response) => {
      setFilesImages(response.data);
      console.log(response.data);
    }
  );

  const onImageDeleteHandler = useCallback(async () => {
    await deleteProductImages(idProduct, selectedImageId);
    setIsUploaded((old) => !old);
    setSelectedImageId(null);
  }, [selectedImageId]);

  const filesImageGrid = fileImages.map((image) => {
    // let src = URL.createObjectURL(image);
    let src = image.path;
    return (
      <Grid item>
        <ImagePreview
          onDeleteHandler={() => setSelectedImageId(image.id_product_image)}>
          <ResponsiveImg src={src} alt="default_img" width={"150px"} />
        </ImagePreview>
      </Grid>
    );
  });

  useEffect(() => {
    requestData();
    setIsUploaded(false);
  }, [isUploaded]);

  const onFileInputChanged = async (e) => {
    console.log(e.target.files);
    //catch error if any
    await uploadProductImages(e.target.form, idProduct);
    // let filesImages = [...e.target.files, ...fileImages];
    // setFilesImages(filesImages);
    setIsUploaded(true);
  };

  return (
    <>
      <Paper
        sx={{
          p: 2,
          width: "100%",
        }}
      >
        <Typography variant="h6" color="text.secondary" gutterBottom>
          Images
        </Typography>
        {!is_loading && (
          <Stack spacing={2}>
            {fileImages?.length > 0 && filesImageGrid}
            <AddFileForm
              value="Upload image"
              onFileInputChanged={onFileInputChanged}
            />
          </Stack>
        )}
        {is_loading && <LoadingCircular />}
      </Paper>
      <DialogConfirmation
        message={"Are you sure you want to delete this image"}
        open={selectedImageId !== null}
        onActionHandler={onImageDeleteHandler}
      />
    </>
  );
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const AddFileForm = (props) => {
  //form

  return (
    <Box component={"form"}>
      <Button
        component="label"
        variant="dashed"
        sx={{
          padding: "32px 16px",
          maxWidth: "200px",
        }}
        fullWidth
      >
        Upload the images
        <VisuallyHiddenInput
          type="file"
          name="files"
          onChange={props.onFileInputChanged}
          accept=".png, .jpeg, .jpg"
          multiple
        />
      </Button>
    </Box>
  );
};
