import React from "react";
import {
  CardHeader,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  FormControl,
  FormLabel,
} from "@mui/material";
import { AccountInfo } from "../../components/account/form/AccountInfo";
import { FormProvider, useForm } from "react-hook-form";
import { LoginInfo } from "../../components/account/form/LoginInfo";

export const AccountSettings = ({ data }) => {
  const methods = useForm();
  return (
    <Container>
      <Grid item direction={"column"} container md={8} gap={2}>
        <Typography variant="h4" color="text.primary">
          Account Settings
        </Typography>
        <Typography variant="h6" color="text.secondary">
          Change the different settings of your account
        </Typography>
        <FormProvider>
          <AccountInfo />
          <LoginInfo />
        </FormProvider>
      </Grid>
    </Container>
  );
};
