import { fetching } from "../utils/request"
import UnauthorizedRequestError from "../utils/Error"

export const getUserAccountInfo = async() =>{

    try{
        const result = await fetching("users");
        return result;
    }
    catch (err){
        if ( err instanceof UnauthorizedRequestError){
            //redirect
            console.log(err.message);
        }
    }
}

export const UpdateUserInfo = () =>{

}