import React from "react";
import {
  CardHeader,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  TextField,
  Box,
  Divider,
} from "@mui/material";
export const AccountInfo = () => {
  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h6" color="text.secondary" component={"span"}>
            Account info
          </Typography>
        }
      ></CardHeader>
      <Divider />

      <CardContent>
        <Box component={"form"}>
          <Grid container direction={"column"} gap={2}>
              <Grid item >
                <FormControl fullWidth>
                  <FormLabel>First name</FormLabel>
                  <TextField id="standard-basic" name="name" size="small" />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl fullWidth>
                  <FormLabel>Last name</FormLabel>
                  <TextField id="standard-basic" name="name" size="small" />
                </FormControl>
              </Grid>
           
            <Grid item container gap={2}>
              <Grid item>
                <FormControl>
                  <FormLabel>Site Url</FormLabel>
                  <TextField id="standard-basic" name="name" size="small" />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl>
                  <FormLabel>Last name</FormLabel>
                  <TextField id="standard-basic" name="name" size="small" />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          
        </Box>
      </CardContent>
    </Card>
  );
};
