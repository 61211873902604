import React , {useCallback, useState} from "react";
import {
  Select,
  FormControl,
  FormLabel,
  MenuItem,
  Container,
  Button,
  Grid
} from "@mui/material";
import { ProductCreateDialog } from "./ProductCreateDialog";

const inventoryOptions = [
  { id: 1, text: "In stock" },
  { id: 2, text: "Out of stock" },
  { id: 3, text: "All" },
];

export const ProductFilter = ({ filterValuesHandler }) => {

    const [currentValue,setCurrentValue] = useState(inventoryOptions[0].id);
    const [isModalProductCreateOpen,setIsModalProductCreateOpen] = useState(false);

    const setFilterValue = (value)=>{
        setCurrentValue(value);
        filterValuesHandler(value)
    };

  const onProductCreateClick = useCallback(()=>{
    setIsModalProductCreateOpen(true)
  },[])

  const onModalProductCloseHandler = useCallback(()=>{
    setIsModalProductCreateOpen(false)
  },[])


  return (
    <>
    <Grid container fullWidth justifyContent={"space-between"}>
      <Grid item>
        <FormControl fullWidth size="small">
          <FormLabel>Inventory</FormLabel>
          <Select
            value={currentValue}
            onChange={(e) => setFilterValue(e.target.value)}
          >
            {inventoryOptions.map(({ id, text }) => (
              <MenuItem value={id}>{text}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid alignSelf={"flex-end"}>
      <Button onClick={onProductCreateClick}>Add a product</Button>
      </Grid>
    </Grid>
    <ProductCreateDialog  open={isModalProductCreateOpen} onCloseHanlder={onModalProductCloseHandler}/>
    </>
  );
};
