import React from "react";
import {
  Stack,
  Typography,
  Container,
  Divider,
  TextField,
  InputAdornment,
  FormControl,
  FormLabel,
  Autocomplete,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  Button,
  Grid,
  Paper,
  IconButton,
} from "@mui/material";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { RowItem } from "../../components/UI/Base";

const top100Films = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
];

export const TypographySys = () => {
  return (
    <Container
      style={{
        border: "1px solid #ccc",
        paddingTop: "40px",
      }}
      sx={{
        "& .MuiStack-root": {
          marginBottom: "40px",
        },
      }}
    >
      <Stack>
        <Typography variant="h1" gutterBottom>
          Typography
        </Typography>
        <Typography variant="body" gutterBottom>
          variations
        </Typography>
        <Divider />
        <Typography variant="h1" gutterBottom>
          Heading 1
        </Typography>
        <Typography variant="h2">Heading 2</Typography>
        <Typography variant="h3">Heading 3</Typography>
        <Typography variant="body1">Body 1</Typography>
        <Typography variant="body2">Body2</Typography>
        <Typography variant="secondary">secondary</Typography>
        <Typography variant="alert">Alert</Typography>
      </Stack>
      <Stack
        sx={{
          "& > div": {
            marginBottom: "20px;",
          },
        }}
      >
        <Typography variant="h1" gutterBottom>
          Input Fields
        </Typography>
        <Typography variant="body" gutterBottom>
          variations
        </Typography>
        <Divider
          sx={{
            marginBottom: "10px",
          }}
        />
        <FormControl>
          <FormLabel>Input with adornment </FormLabel>
          <TextField
            id="standard-basic"
            min="0.0"
            max="10000.0"
            step="0.1"
            name="regular_price"
            size="small"
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">CAD $</InputAdornment>
              ),
            }}
          />
        </FormControl>
        <Stack direction={"row"} gap={"24px"}>
          <FormControl>
            <FormLabel>Input regular </FormLabel>
            <TextField
              id="standard-basic"
              min="0.0"
              max="10000.0"
              step="0.1"
              name="regular_price"
              size="small"
              type="text"
            />
          </FormControl>
          <FormControl>
            <FormLabel>Autocomplete Input regular </FormLabel>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={top100Films}
              sx={{ width: 300 }}
              size="small"
              renderInput={(params) => (
                <TextField
                  id="standard-basic"
                  name="regular_price"
                  type="text"
                  {...params}
                />
              )}
            />
          </FormControl>
        </Stack>
        <Typography variant="lead">Rich text editor</Typography>
        <br />
        {/* need to check how to custmize it */}
        <ReactQuill theme="snow" />
        <FormControl>
          <FormLabel>Select input</FormLabel>
          <Select size="small" sx={{ width: 300 }}>
            <MenuItem value={"default"}>Default</MenuItem>
            <MenuItem value={"default1"}>Default 1</MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Checkbox input</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="Label"
            />
          </FormGroup>
        </FormControl>
        <Box
          sx={{
            "& > button": {
              marginRight: 16,
            },
          }}
        >
          <Typography variant="h1" gutterBottom>
            Buttons
          </Typography>
          <Button variant="contained">Save</Button>
          <Button variant="text">Cancel</Button>
        </Box>
        <Grid container justifyContent={"center"} direction={"column"} gap={1}>
          <Grid
            item
            container
            justifyContent={"center"}
            direction={"row"}
            gap={1}
          >
            <Paper
              sx={{
                padding: 3,
              }}
            >
              Elements
            </Paper>
            <Paper
              sx={{
                padding: 3,
              }}
            >
              Elements
            </Paper>
            <Paper
              sx={{
                padding: 3,
              }}
            >
              Elements
            </Paper>
          </Grid>
          <Grid item container direction={"row"} gap={1}>
            <Grid item flexGrow={1}>
              <Paper
                sx={{
                  padding: 3,
                }}
              >
                Elements
              </Paper>
            </Grid>
            .
            <Paper
              sx={{
                padding: 3,
              }}
            >
              Elements
            </Paper>
          </Grid>
        </Grid>
        <Grid>
          
        </Grid>
        <Grid>
          <RowItem>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <Typography>
              hello world
            </Typography>
            <IconButton>
            <MoreHorizIcon/>
          </IconButton>
            </Stack>
          </RowItem>
        </Grid>
      </Stack>
    </Container>
  );
};
