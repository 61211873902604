import './App.css';
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import "./index.css";
import RootLayout from "./pages/RootLayout";
import ErrorPage from "./pages/Layout/error/ErrorPage";
import Home from "./pages/Home/Home";
import { InventoryMenu } from "./pages/Inventory/Menus/InventoryMenu";
// import ProductCreate from './pages/Product/ProductCreate';
import Users from "./pages/Users";
import { ProductList } from "./pages/Inventory/Product/ProductList";
import { Login } from "./pages/Login/Login";
import { AuthProvider } from "./components/authentification/AuthProvider";
import ProductCreate from "./pages/Inventory/Product/ProductCreate";
import { AccountSettings } from "./pages/Account/AccountSettings";
import { getAllProduct, getProduct } from "./services/productService";
import { getUserAccountInfo } from "./services/accountSerivce";
import { getAllMenus } from "./services/menuServices";
import { Category } from "./pages/Inventory/Categories/Category";
import { MenuCategories } from "./pages/Inventory/Menus/MenuCategories";
import { getCategories } from "./services/CategoryService";
import { getMenuCategories } from "./services/menuServices";
import { TypographySys } from "./pages/designSys/TypographySys";
import { tokenLoader } from "./services/authService";

//theme
import { theme } from "./components/UI/theme";
import { ThemeProvider } from "@emotion/react";
import {Location} from "./pages/First/Location";
import React from "react";

const router = createBrowserRouter([
    {
        path: "store-location/:idStoreLocation/",
        element: <RootLayout />,
        errorElement: <ErrorPage />,
        loader:tokenLoader,
        children: [
            {
                index:true,
                element: <Home/>,
            },
            {
                path: "add-location",
                element: <Location/>,

            },
            {
                path: "dashboard/",
                element: <Home/>,
            },
            {
                path: "test/",
                element: <Home/>,
            },
            {
                path: "products/",
                element: <ProductList/>,
                loader:getAllProduct
            },
            {
                path:"product/:idProduct?/",
                element: <ProductCreate/>,
                loader:getProduct
            },
            {
                path:"menus",
                element: <InventoryMenu/>,
                loader:getAllMenus
            },
            {
                path:"categories",
                element: <MenuCategories/>,
                loader:getMenuCategories
            },
            {
                path:"categories/:idCategory?/",
                element: <Category/>,
                loader:getCategories

            }
            ,
            {
                path: "users/",
                element: <Users />,
            },
            {
                path:"account",
                element:<AccountSettings/>,
                loader:getUserAccountInfo
            },

        ],
    },
    {
        path: "/login",
        element: <Login />,
    },
    {
        path:"/systemD/Typography",
        element: <TypographySys/>,
    },
    {
        path:"/",
        element: <Navigate to={"/login"} replace={true}/>
    }
   
]);


function App() {
  return (
      <ThemeProvider theme={theme}>
          <AuthProvider>
              <RouterProvider router={router} />
          </AuthProvider>
      </ThemeProvider>
  );
}

export default App;
