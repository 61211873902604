import React, { useState } from "react";
import {
  Card,
  Grid,
  CardContent,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Stack,
} from "@mui/material";
import { ResponsiveImg, RowItem } from "../UI/Base";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MoreHoriz } from "@mui/icons-material";

/**
 * @typedef {{children: string,
 *  onClick: CallableFunction,
 * menuOption:React.JSX.Element
 * }} CardInventoryProps
 * @param {CardInventoryProps} props
 * @returns
 */

export const CardInventoryBase = (props) => {
  return <RowItem>{props.children}</RowItem>;
};

/**
 *
 * @typedef {{parentRef:React.ElementRef,
 * open:Boolean,
 * handleClose:CallableFunction,
 * actionMenuItems: OptionActionMenu
 *
 * }} OptionMenuProps
 *
 * @param {OptionMenuProps} props
 * @returns
 */
const OptionMenu = (props) => {
  const handleAction = (name, action) => {
    //close the option menu
    props.handleClose();
    action(name);
  };

  return (
    <Menu
      sx={{
        minWidth: "200px",
      }}
      open={props.open}
      anchorEl={props.parentRef}
      onClose={props.handleClose}
    >
      {props.actionMenuItems &&
        props.actionMenuItems.map(({ title, action, icon }) => (
          <MenuItem onClick={() => handleAction(props.name, action)}>
            {icon} {title}
          </MenuItem>
        ))}
    </Menu>
  );
};

/**
 * @typedef {{name: string , subContent:? string  }} ContentObject
 */

/**
 * @typedef {Array<{ title:String,
 *                  action:CallableFunction,
 *                  icon:React.ReactElement}>}  OptionActionMenu
 */

/**
 * @typedef {{ children:React.JSX.Element,
 *   option:Boolean,
 *   content: ContentObject,
 *    image:string,
 *    editCallback :?CallableFunction,
 *    onOptionClick:?CallableFunction
 *    optionActionMenu:? OptionActionMenu
 * }
 * } CardInventoryContentProps
 * @param {CardInventor ContentProps} props
 * @returns
 */

export const CardInventoryContent = (props) => {
  const [anchor, setAnchor] = useState(null);
  const open = Boolean(anchor);

  const handleClose = (event) => {
    setAnchor(null);
    
  };

  const onOptionClick = (event) => {
    setAnchor(event.currentTarget);
  };

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent={"space-between"}
        flexWrap={"nowrap"}
      >
        <Grid
          item
          xs
          maxWidth="false"
          direction="column"
          alignContent={"start"}
          marginTop={"10px"}
        >
          <Typography
            gutterBottom
            variant="h2"
            color={"primary.black"}
            component="div"
            onClick={props.onClick}
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            {props.content?.name}
          </Typography>
          <Typography gutterBottom variant="body2" component="div">
            {props.content?.subContent}
          </Typography>
        </Grid>
        <Grid>
          {props.option && (
            <IconButton>
              <MoreHoriz onClick={onOptionClick} />
              <OptionMenu
                name={props.content?.name}
                actionMenuItems={props.optionActionMenu}
                open={open}
                parentRef={anchor}
                handleClose={handleClose}
              />
            </IconButton>
          )}
        </Grid>
      </Stack>
    </Box>
  );
};
