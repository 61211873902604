import {
  Container,
  CardContent,
  Grid,
  Card,
  Box,
  Input,
  FormControl,
  FormLabel,
  TextField,
  Button,
  Typography,
  CardHeader,
  Stack,
  Alert,
  Link
} from "@mui/material";
import React, { useContext, useState } from "react";

import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import { signin } from "../../services/authService";
import { useAuth } from "../../hooks/use-auth";
import loginBackground from "../../static/login-background.png";
import compagnyLogo from "../../static/yenda_logo_1.png";
import { ResponsiveImg } from "../../components/UI/Base";
import {redirect} from "react-router-dom";

export const Login = () => {
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const { userContextInfo, setUserContextInfo } = useAuth();
  const [loginError, setLoginError] = useState(false);
  const location = useLocation();

  const errorMessage = location.state?.errorMessage;

  console.log(errorMessage);

  const onSubmit = async ({ email, password }) => {
    //TODO: Add try catch block tomcatch the differents errors
    //TODO add a promise chain to navigate to a new url on succes
    let user = null;

    try {
      user = await signin({ email, password });
    } catch (e) {
      setLoginError(true);
      return;
    }
    if (user === null) {
      setLoginError(true);
    } else {
      
      setUserContextInfo({...userContextInfo, ...user.data});
      //set the access token on the local Storage
      localStorage.setItem("_TOKEN", user.data.jsontoken);
      navigate("/store-location/1/");
    }
  };


  if(errors){
    console.log("The form errors are :", errors)
  }
  //use hidden input field for manual setup :)
  return (
    <Box sx={{}}>
      <Grid
        container
        direction={"row"}
        sx={{
          height: "100vh",
        }}
        wrap="nowrap"
      >
        <Grid
          item
          md={6}
          sx={{
            display: { xs: "none", sm: "none", md: "block" },
            background: "#3062D4",
          }}
        ></Grid>
        <Grid item container md={8} justifyContent={"center"}>
          <Box
            component={"form"}
            onSubmit={handleSubmit(onSubmit)}
            sx={{
              mt: 4,
              // minWidth: "420px",
            }}
          >
            <Stack direction={"column"} gap={3} justifyContent={"center"}>
              <Box
                sx={{
                  margin: "auto",
                  mb: 2,
                }}
              >
                <ResponsiveImg src={compagnyLogo} width={"160px"} />
              </Box>
              <Box
                sx={{
                  mb: 1,
                  margin: "auto",
                  textAlign:"center"
                }}
              >
                <Typography variant="h2" gutterBottom>
                  Login into your account
                </Typography>
                <Typography variant="subtitle2">
                  Enter your email and password to access your account
                </Typography>
               
              </Box>
              <FormControl fullWidth>
                <FormLabel htmlFor="email">Email address</FormLabel>
                <TextField
                  name="email"
                  size="small"
                  type="text"
                  {...register("email", {
                    required: "You need to enter an email address",
                    pattern:/^\S+@\S+$/i
                  })}
                />
                {errors.email?.type === "required" && (
                  <Typography variant="alert">{errors.email?.message}</Typography>
                )}
                {errors.email?.type === "pattern" && (
                  <Typography variant="alert">You entered the wrong email address</Typography>
                )}
              </FormControl>

              <FormControl fullWidth error={errors.password?true:false} >
                <FormLabel htmlFor="password">Password</FormLabel>
                <TextField
                  error={errors.password?true:false}
                  name="password"
                  type="password"
                  size="small"
                  {...register("password", {
                    required: true,
                    maxLength: 20,
                    minLength: 5,
                  })}
                />
                {errors.password?.type === "required" && (
                  <Typography variant="alert">
                    The entered password is not valid
                  </Typography>
                )}
                {errors.password?.type === "maxLength" && (
                  <Typography variant="error">
                    The entered password is too long
                  </Typography>
                )}
                {errors.password?.type === "minLength" && (
                  <Typography variant="error">
                    The entered password is too short
                  </Typography>
                )}
              </FormControl>

              <Button type="submit" variant="contained" size="medium" fullWidth>
                Login
              </Button>

              {loginError && (
                <Box textAlign={"center"}>
                  <Alert severity="error">
                    The entered credentials are not valid
                  </Alert>
                </Box>
              )}
               <Link style={{
                textAlign:"center"
               }} to="#">Did you forget your password</Link>
            </Stack>
          </Box>
        </Grid>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      </Grid>
    </Box>
  );
};
