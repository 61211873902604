import React from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';

const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'image', 
    headerName: 'Image',
    width: 130  ,
    renderCell:(params) => <img width="80"  alt="no" src={params.row.image}/>},
  { field: 'name', headerName: 'Name', width: 130 ,
    renderCell:(params) =>{
        return <><Link relative={"path"} to={`../product/${params.row.id}`}>{params.row.name}</Link>
        </>
    } 
  },
  { field: 'slug', headerName: 'Slug', width: 130 },
  { field: 'description', headerName: 'Description', width: 130 },
  {
    field: 'price',
    headerName: 'Price',
    type: 'number',
    width: 90,
  }
];

export const ProductGrid = ({products}) => {
  return (
    <div style={{ height: 400, width: '100%', backgroundColor:"white" }}>
      <DataGrid
        rows={products}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        autoHeight
        pageSizeOptions={[5, 10]}
        checkboxSelection
      />
    </div>
  )
}
